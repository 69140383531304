import { ApiEndpoint, createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'

export type CategoryGroup =
  | ''
  | 'events'
  | 'restaurants'
  | 'my_club'
  | 'dining'
  | 'meeting_rooms'
  | 'hotel_rooms'
  | 'club_benefits'
  | 'club_directory'
  | 'appointments'
  | 'spa_wellness'
  | 'spa'

export type CategoryType =
  | 'general'
  | 'crm'
  | 'contest'
  | 'coupon'
  | 'survey'
  | 'review'
  | 'event'
  | 'ticket'
  | 'billing'
  | 'content'
  | 'locator'
  | 'newsletter'
  | 'text_message'
  | 'social_post'
  | 'campaign'
  | 'loyalty'
  | 'membership'
  | 'appointment'
  | 'directory_location'
  | 'service'
  | 'product'
  | 'order'
  | 'appointment_slot'
  | 'transaction'
  | 'reward_activity'
  | 'keyword'
  | 'credit'
  | 'webhook'
  | 'loyalty_redemption'
  | 'membership_card'
  | 'note'
  | 'survey_group'
  | 'funding_campaign'
  | 'membership_card_activity'
  | 'gallery'

export type CategoryQuery = {
  type: CategoryType
}

class Category extends ApiEntity implements Data.Category {
  static urlRoot = `/api/categories`

  readonly name: string = ''
  readonly group: string = ''
  readonly order: number = 0
  readonly featured: boolean = false
  readonly description: string = ''
  readonly graphicThumb: string = ''
  readonly categoryAbove: number = 0
  readonly parentCategory?: Category
  readonly childCategories?: Category[]

  get image(): string {
    return this.graphicThumb
  }

  get images(): string[] {
    return [this.image]
  }

  get slug(): string {
    return this.name.trimEnd().replaceAll(' ', '-').toLowerCase()
  }

  get mainGroup(): string {
    return this.parentCategory ? this.parentCategory.group : this.group
  }

  static extract<T extends Data.Categorized<Category>>(data: T[]): Category[] {
    return data
      .map((it) => it.categories)
      .flat()
      .filter((value, index, self) => self.findIndex((it) => `${it.id}` === `${value.id}`) === index)
  }
}

const CategoryBaseResource = createApiResource({
  path: '/api/public/categories/:id',
  schema: Category,
})

const group = new ApiEndpoint({
  path: '/api/public/categories/groups/:group',
  schema: [Category],
})

const CategoryResource = {
  ...CategoryBaseResource,
  getList: CategoryBaseResource.getList.extend({
    schema: [Category],
    searchParams: {} as { type?: CategoryType },
  }),
  group,
}

export { Category, CategoryResource }
