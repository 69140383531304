import { FC, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'

import { Col, Flex, Row, Typography, notification } from 'antd'

import { ApiError } from '@peoplevine/sdk/api'
import IvyIcon from '@peoplevine/sdk/components/icon'
import { HorizontalSpace, VerticalSpace } from '@peoplevine/sdk/components/layout'
import { StepsSlider } from '@peoplevine/sdk/components/steps/StepsSlider'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { AccountRegistered } from '@peoplevine/sdk/datasource/account/account'
import { useI18n } from '@peoplevine/sdk/providers'
import { useAuth } from '@peoplevine/sdk/providers/Auth'
import { useNotification } from '@peoplevine/sdk/providers/Notification'
import routes from '@peoplevine/sdk/routes'

import SendActivationCode from './activate/SendAccountActivation'
import { LoginPasswordStep, LoginUsernameStep } from './form/LoginForm'

interface ILoginView {
  title?: string
  message?: string
  pushHistory?: boolean
}

type StepType = 'username' | 'password' | 'activate'
const steps = ['username', 'password', 'activate']

const currentStep = (step) => {
  switch (step) {
    case 'username':
    case 'password':
      return 0
    case 'activate':
      return 1
  }
}

const LoginView: FC<ILoginView> = ({ title = 'Login', message: description = '' }) => {
  const [step, setStep] = useState<StepType>('username')
  const [faceId, setFaceId] = useToggle(false)
  const [account, setAccount] = useState<Omit<AccountRegistered, 'pk'> & { username: string }>()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { notify, notifyOnError } = useNotification()
  const [activeSubStep, setActiveSubStep] = useState(0)
  const { authenticated } = useAuth()
  const { t } = useI18n()
  const handleError = (error: ApiError) => {
    if (error.error === 'pending_email_verification') {
      notify({
        type: 'warning',
        key: 'email-verification-required',
        icon: <IvyIcon type={'application/mail'} />,
        message: 'Email Verification Required',
        description: (
          <Flex vertical gap={2}>
            <Text>{error?.message}</Text>
            <Text>If you haven&apos;t received your verification email,</Text>
            <Typography.Link
              onClickCapture={() => {
                notification.destroy('email-verification-required')
              }}
              href={routes.verifyEmail}
            >
              click here to send another.
            </Typography.Link>
          </Flex>
        ),
        duration: 0,
      })
    } else {
      if (error.error === 'login_attempts_exceeded') {
        notifyOnError({
          name: 'Too many login attempts',
          message: error.message,
        })
      } else {
        notifyOnError({ message: 'Invalid username/email and password combination' })
      }
    }
  }

  const loginSteps = [
    {
      name: 'username',
      element: (
        <LoginUsernameStep
          onSuccess={(result) => {
            setAccount(result)
            setStep('password')
            setActiveSubStep(1)
            setFaceId(true)
          }}
          onError={(account) => {
            setAccount(account)
            setStep('activate')
          }}
        />
      ),
    },
    {
      name: 'password',
      element: (
        <VerticalSpace>
          {account?.username && (
            <LoginPasswordStep
              username={account?.username}
              onSuccess={() => navigate(pathname, { replace: true })}
              faceId={faceId}
              onError={handleError}
            />
          )}
          <HorizontalSpace justify={'space-between'}>
            <Typography.Link
              onClick={() => {
                setAccount(undefined)
                setStep('username')
              }}
            >
              {t('Back to login')}
            </Typography.Link>
            <Typography.Link href={routes.forgotPassword}>{t('Forgot password')}</Typography.Link>
          </HorizontalSpace>
        </VerticalSpace>
      ),
    },
  ]

  if (authenticated) return <Navigate to={routes.myClub} replace={true} />

  return (
    <StepsSlider
      active={currentStep(step)}
      elements={[
        <VerticalSpace key={0} size={16} style={{ width: '100%' }}>
          <VerticalSpace size={16}>
            <Title level={1}>{title}</Title>
            {account?.username && (
              <Text translate={'no'} icon={'application/user'} onClick={() => setStep('username')}>
                {account.username}
              </Text>
            )}
            <Text type={'secondary'}>{description}</Text>
          </VerticalSpace>
          <StepsSlider active={activeSubStep} elements={loginSteps.map((step) => step.element)} />
        </VerticalSpace>,
        <VerticalSpace key={1}>
          {account && !account?.activated && <SendActivationCode {...account} />}
          <Typography.Link onClick={() => setStep('username')}>{t('Back to login')}</Typography.Link>
        </VerticalSpace>,
      ]}
    />
  )
}

const Login: FC = () => {
  return (
    <Row wrap justify={'center'} align='middle'>
      <Col xs={20} md={18} lg={20} xl={12}>
        <LoginView />
      </Col>
    </Row>
  )
}

export { Login as default, LoginView }
