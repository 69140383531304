import { Children, FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Col, ListProps, Row, TableColumnProps } from 'antd'

import { AsyncBoundary, useSuspense } from '@data-client/react'

import { createApiResource } from '../../datasource/api/endpoint'
import { ApiEntity } from '../../datasource/api/entity'
import { PaginatedHeader } from '../../datasource/api/pagination'
import { Card, CardProps } from '../card'
import { Section } from '../layout'
import { Result } from '../result'
import { useResponsive } from '../screen'

type PaginatorSkeletonProps = {
  skeleton?: ReactNode
  count?: number
}

export type InfiniteGridProps<T extends ApiEntity = ApiEntity> = {
  dataSource?: T[] | undefined
  onNextPage?: () => Promise<void>
  filters?: Record<string, any>
  scrollToTop?: boolean
  loading?: boolean
  className?: string
  children?: ReactNode
  header?: ReactNode
  onEmpty?: ReactNode
  CardComponent?: FC<Data.Source<T>>
  EmptyComponent?: FC
  columns?: TableColumnProps<T>
  onRowClick?: (item: T) => void
  pagination?: Partial<API.Pagination>
  skeleton?: PaginatorSkeletonProps
}

const skeletonData = (size: number) =>
  new Array(size).fill(0).map((_, i) => ({
    pk: () => `${i}`,
  }))

function InfiniteGrid<T extends ApiEntity = ApiEntity>({
  onNextPage = () => Promise.resolve(),
  onEmpty = <Empty />,
  EmptyComponent = () => onEmpty,
  pagination = new PaginatedHeader(),
  dataSource: data = [],
  CardComponent = () => <Card loading={true} image={{ loading: true }} />,
  loading: initialLoading,
  header,
}: InfiniteGridProps<T>) {
  const { gridSize: pageSize } = useResponsive()
  const [loading, setLoading] = useState(initialLoading)
  const loadMore = useCallback(async () => {
    setLoading(true)
    await onNextPage()
    setLoading(false)
  }, [onNextPage])

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && !loading && pagination?.hasNextPage) {
      loadMore().then()
    }
  }, [inView, loadMore, loading, pagination?.hasNextPage])

  return (
    <Section title={header}>
      {data?.length > 0 && (
        <DataGrid
          data={data}
          size={pagination?.pageSize ?? pageSize}
          EmptyComponent={EmptyComponent}
          CardComponent={CardComponent}
        />
      )}
      <br ref={ref} />
      {loading ? (
        <DataGrid
          loading={loading}
          data={data}
          size={pagination?.pageSize ?? pageSize}
          CardComponent={() => <Card loading={loading} image={{ loading }} />}
        />
      ) : null}
    </Section>
  )
}
export type GridProps<T extends ApiEntity = ApiEntity> = Pick<ListProps<T>, 'grid' | 'renderItem' | 'className'> & {
  resource: Pick<ReturnType<typeof createApiResource<new () => T>>, 'infinitePagination' | 'paginatedList'>
  header?: string
  loading?: boolean
  params?: any
  EmptyComponent?: FC<Partial<CardProps>>
  CardComponent?: FC<Partial<Data.Source<T> & Data.Loadable>>
}

const Empty = () => (
  <Result.NoData
    title={'Nothing to see here'}
    subTitle={'We are in the process of adding new and exciting things.  Be sure to check back soon!'}
  />
)

type DataGridProps<T extends ApiEntity = ApiEntity> = PropsWithChildren<
  Data.Source<T[]> &
    Data.Loadable & {
      size?: number
      EmptyComponent: FC
      CardComponent: FC<Partial<Data.Source<T>> & Data.Loadable>
    }
>

const GridCol: FC<{ size: number; index: number } & PropsWithChildren> = ({ index, size = 1, ...props }) => (
  <Col span={Math.floor(24 / size)} {...props} />
)

const DataGrid = ({
  data = [],
  size: initialSize = data?.length,
  EmptyComponent = Empty,
  CardComponent = () => <Card loading={true} image={{ loading: true }} />,
  loading = false,
}: Partial<DataGridProps>) => {
  const { gridSize } = useResponsive()
  const size = initialSize ?? gridSize
  return (
    <Row gutter={[32, 32]}>
      {data?.map((data) => (
        <Col key={data.pk()} span={Math.floor(24 / size)}>
          <CardComponent data={data} loading={loading} />
        </Col>
      ))}

      {loading
        ? Children.map(
            skeletonData(size)?.map((_, index) => <CardComponent loading={loading} key={index + data?.length} />),
            (child, index) => (
              <GridCol index={index} size={size}>
                {child}
              </GridCol>
            ),
          )
        : data &&
          data.length === 0 && (
            <Col span={24} style={{ height: '100%' }}>
              <EmptyComponent />
            </Col>
          )}
    </Row>
  )
}

function Grid<T extends ApiEntity = ApiEntity>({
  resource,
  params,
  loading = false,
  EmptyComponent = Empty,
  CardComponent = () => <Card loading image={{ loading: true }} />,
  children,
}: PropsWithChildren<GridProps<T>>) {
  const data = useSuspense(resource.paginatedList, { ...params })
  return (
    <AsyncBoundary fallback={<DataGrid loading={loading} size={params?.pageSize} />}>
      <DataGrid
        loading={loading}
        data={data.results}
        EmptyComponent={EmptyComponent}
        CardComponent={CardComponent}
        size={params?.pageSize}
      >
        {children}
      </DataGrid>
    </AsyncBoundary>
  )
}

export { Grid, DataGrid, InfiniteGrid }
export default InfiniteGrid
