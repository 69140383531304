import { FC, useEffect } from 'react'

function createLinkElement(url: string): Element {
  const element = document.createElement('link')
  element.rel = 'stylesheet'
  element.href = url
  return element
}

function resolveFamily(name = '') {
  return name
    .replace('sans-serif', '')
    .replace('serif', '')
    .replaceAll('"', '')
    .replaceAll("'", '')
    .split(',')[0]
    .trim()
}

function resolveUrl(name: string) {
  return `https://fonts.googleapis.com/css2?family=${name}:wght@400;500;600;700;800;900&display=swap`
}

const FontLoader: FC<{ fonts: string[] }> = ({ fonts = [] }) => {
  useEffect(() => {
    const elements = fonts
      .map(resolveFamily)
      .map(resolveUrl)
      .map(createLinkElement)
      .map((element) => document.head.appendChild(element))
    return () => {
      elements?.map((element) => document.head.removeChild(element))
    }
  }, [fonts])

  return null
}

export { FontLoader }
