import { PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

type AppMessage =
  | 'OPEN_MENU'
  | 'CLOSE_MENU'
  | 'CONTACTS'
  | 'DIGITAL_ID'
  | 'OPEN_PDF'
  | 'USERNAME'
  | 'LOGIN'
  | 'FACE_ID'
  | 'CHANGE_NETWORK'
  | 'HOME_HOUSE'
  | 'LOG'
  | 'FETCH_HOUSE'

type EventCallback = {
  event: AppMessage
  callback: (data: any) => void
}

type Dispatch = {
  type: AppMessage
  body?: any
}

type NativeAppContext = {
  isNativeApp: boolean
  detectDevice: { isMobile: boolean }

  subscribe: (evt: EventCallback) => EventCallback
  unsubscribe: (evt: EventCallback) => void
  dispatch: (data: Dispatch) => void
  log: (evt: string, message?: any) => void
}

const defaultValue: NativeAppContext = {
  isNativeApp: false,
  detectDevice: { isMobile: false },
  subscribe: () => ({
    event: 'OPEN_MENU',
    callback: () => {},
  }),
  unsubscribe: () => {},
  dispatch: () => {},
  log: () => {},
}

const NativeApp = createContext<NativeAppContext>(defaultValue)

const subscriptions: EventCallback[] = []

const dispatch = (data: any) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  }
}

const isNativeApp = Boolean(!!(window as any).ReactNativeWebView)

function NativeAppProvider({ children }: PropsWithChildren) {
  const log = (evt: string, message?: any) => {
    dispatch({
      type: 'LOG',
      body: {
        event: evt,
        message: message,
      },
    })
  }
  const subscribe = (cb: EventCallback) => {
    subscriptions.push(cb)
    return cb
  }

  const unsubscribe = (cb: EventCallback) => {
    const index = subscriptions.indexOf(cb)
    if (index > -1) {
      subscriptions.splice(index, 1)
    }
  }

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.body.classList.add('is-mobile-app')
      window.ReactNativeWebView.onDispatch = (msg: AppMessage, data: any) => {
        for (const cb of subscriptions) {
          if (cb.event === msg) {
            cb.callback(JSON.parse(data))
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    isNativeApp && window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'done' }))
  }, [])

  return (
    <NativeApp.Provider
      value={{
        detectDevice: { isMobile },
        isNativeApp,
        subscribe,
        unsubscribe,
        dispatch,
        log,
      }}
    >
      {children}
    </NativeApp.Provider>
  )
}

const useNativeApp: () => NativeAppContext = () => useContext(NativeApp)

export { NativeAppProvider, useNativeApp }
