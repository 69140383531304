import { FC } from 'react'

import { Drawer, DrawerProps } from 'antd'

const FullScreenDrawer: FC<DrawerProps> = ({ ...baseProps }) => {
  return (
    <Drawer
      motion={{
        motionName: 'fade',
      }}
      placement={'top'}
      height={'100%'}
      width={'100%'}
      {...baseProps}
    />
  )
}

export { FullScreenDrawer }
