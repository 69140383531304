import { FC } from 'react'

import { Image as BaseImage } from 'antd'

const assets: Record<`./assets/placeholders/${Design.PlaceholderIcon}.svg`, string> = import.meta.glob(
  './assets/placeholders/*.svg',
  {
    import: 'default',
    eager: true,
  },
)

const optimizedSrc = (src: string, number: 1 | 2 | 3, ratio: number) =>
  `${src.substring(0, src.length - 4)}@${number}${src.substring(src.length - 4)} ${ratio}w`

const Image: FC<SDK.Components.ImageProps> = ({
  src: defaultSrc = '',
  alt = '',
  placeholder = 'empty',
  preview = false,
  loading = false,
  className,
  style,
  fallback = assets[`./assets/placeholders/${placeholder}.svg`],
  ...props
}) => {
  const src = !loading ? defaultSrc : fallback

  return (
    <BaseImage
      wrapperStyle={{
        width: '100%',
        height: props.height,
        justifyContent: 'center',
        margin: '0 auto',
        alignItems: 'center',
        display: 'inline-flex',
      }}
      style={{
        objectFit: src !== fallback ? 'cover' : 'contain',
        objectPosition: 'center',
        background: fallback && `#f8f8f8 url(${fallback}) no-repeat center center`,
        textIndent: '-9999px',
        width: '100%',
        ...style,
      }}
      height={'100%'}
      width={'100%'}
      src={!loading ? src : ''}
      alt={''}
      preview={preview}
      placeholder={null}
      {...props}
    />
  )
}

export { Image }
