import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { report as reportWebVitals } from '@peoplevine/sdk/services/webVitals'

import RootProvider from './containers/RootProvider'
import { initSentry } from './containers/Sentry'

initSentry()

const element = document.querySelector('#root')
if (element) {
  createRoot(element).render(
    <StrictMode>
      <RootProvider />
    </StrictMode>,
  )
}

reportWebVitals()
