import { FC, KeyboardEvent, ReactElement, useMemo, useState } from 'react'

import { FormItemProps, FormRule, Input } from 'antd'

import { Item } from '.'
import { isValidEmail } from '../../helpers/strings'
import { useI18n } from '../../providers'
import { withPrefix } from '../../providers/ConfigProvider'
import Text from '..//text/Text'
import { Descriptions } from '../descriptions'
import PhoneNumber from './phone/PhoneNumber'

function preventTab(evt: KeyboardEvent) {
  if (evt.code === 'Tab') evt.preventDefault()
}

const FormItemRules: { [key: string]: FormRule[] } = {
  email: [
    {
      // type: 'email',
      message: 'Please enter a valid email address',
      pattern: /^\w+([.+-]?\w+)*@\w+([.+-]?\w+)*(\.\w{2,3})+$/,
      validator: (rule: FormRule, email: string, source) => {
        return new Promise<string | undefined>((resolve, reject) => {
          if (isValidEmail(email)) {
            resolve(email)
          } else {
            reject('Please enter a valid email address')
          }
        })
      },
    },
  ],
}

export type FormProps<T = any> = {
  errorMessage?: string
  enableTab?: boolean
  disabled?: boolean
  placeholder?: string
  value?: T
  initialValue?: T
  country?: string
  noLabel?: boolean
  onValidate?: (valid: boolean) => void
} & FormItemProps

const Email: FC<FormProps> = ({
  placeholder = 'Email Address',
  errorMessage,
  enableTab = true,
  label = 'Email Address',
  name = 'email',
  required,
  disabled,
  onValidate,
  ...props
}) => (
  <Item
    data-cy={'Email'}
    label={label}
    name={name}
    rules={[
      {
        required: required,
        message: 'Please enter a valid email address',
      },
    ]}
    {...props}
  >
    <Input inputMode={'email'} disabled={disabled} onKeyDown={(evt) => !enableTab && preventTab(evt)} type={'email'} />
  </Item>
)

const passwordValidator = (password: string) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return {
    minChars: password.length >= 8,
    upper: password.toLowerCase() !== password,
    lower: password.toUpperCase() !== password,
    numberOrSymbol: /\d/.test(password) || specialChars.test(password),
  }
}

const PasswordStrengthIndicator: FC<{ input: string; onValidate?: (valid: boolean) => void }> = ({
  input,
  onValidate,
}) => {
  const descriptions = {
    minChars: 'At least 8 characters long',
    upper: '1 uppercase letter',
    lower: '1 lowercase letter',
    numberOrSymbol: '1 number or special character',
  }

  const validation = useMemo(() => passwordValidator(input), [input])

  const indicators: ReactElement[] = useMemo(() => {
    const level = Object.values(validation).filter((v) => !!v).length
    onValidate && onValidate(level >= 4)
    const elements: ReactElement[] = []
    for (let i = 1; i < 5; i++) {
      elements.push(
        <div
          key={`password-indicator-${i}`}
          className={withPrefix(
            `password-strength-indicator`,
            `password-strength-indicator-${i <= level ? level : 'none'}`,
          )}
        />,
      )
    }
    return elements
  }, [onValidate, validation])

  return (
    <div className={withPrefix('password-strength-wrapper')}>
      <div className={withPrefix('password-strength-indicators')}>{indicators}</div>
      <Descriptions
        data={Object.keys(validation).map((v) => {
          return {
            icon: `symbol/${!!validation[v] ? 'check' : 'close'}`,
            text: <Text type={!!validation[v] ? 'success' : 'error'}>{descriptions[v]}</Text>,
          }
        })}
      />
    </div>
  )
}

const Password: FC<FormProps & { showLevel?: boolean; onValidate?: (valid: boolean) => void }> = ({
  showLevel = false,
  label = 'Password',
  placeholder = 'Enter your password',
  disabled,
  onValidate,
  ...props
}) => {
  const [value, setValue] = useState('')
  const { t } = useI18n()
  return (
    <Item
      preserve={false}
      name={props.name ? props.name : 'password'}
      label={label}
      required
      help={showLevel && <PasswordStrengthIndicator input={value} onValidate={onValidate} />}
      rules={[
        {
          pattern: /([[:alnum]])([[:alnum]])([[:alnum:]])([[:alnum]]{5,})/,
          validator: (rule: FormRule, password: string, source) => {
            return new Promise<string | undefined>((resolve, reject) => {
              const validate = passwordValidator(password)
              if (Object.values(validate).filter((v) => v).length >= 4) {
                onValidate && onValidate(true)
                resolve(password)
              } else {
                onValidate && onValidate(false)
                reject()
              }
            })
          },
        },
      ]}
    >
      <Input.Password onChange={(evt) => setValue(evt.target.value)} disabled={disabled} />
    </Item>
  )
}

const FormItem = {
  Password,
  Email,
  Phone: PhoneNumber,
  FormItemRules,
} as const

export { FormItem }
