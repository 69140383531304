import { DateTz } from '../../../api'
import ApiEntity from '../../api/entity'
import { CompanyConfig } from '../../company'
import { Order } from '../../orders/order'
import { Scheduler, TimeSlot } from '../../scheduler'
import { TransactionRefundItem } from '../../transaction'
import { AccountBooking } from './booking'

class AccountAppointment extends AccountBooking {
  static urlRoot = `/api/account/appointments`
  readonly id: Data.ID = ''
  readonly schedule: Scheduler = Scheduler.fromJS()
  readonly blockType: string = ''
  readonly slot: TimeSlot = TimeSlot.fromJS()
  readonly status: API.AccountBookingStatus = 'pending'
  readonly partySize: number = 0
  readonly canCancel: boolean = false
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly timezoneId: string = ''
  readonly hasSurvey: boolean = false
  readonly surveyId: number = 0
  readonly company: CompanyConfig = CompanyConfig.fromJS({})
  readonly type: API.ScheduleType = 'appointment'
}

export class CancelAppointmentResponse
  extends ApiEntity
  implements API.CancelBookingResponse<Order, TransactionRefundItem, AccountAppointment>
{
  static schema = {
    order: Order,
    refunds: [TransactionRefundItem],
    booking: AccountAppointment,
  }
  orderId?: Data.ID
  order?: Order
  refunds?: TransactionRefundItem[]
  amount: number
  message: string
  success: boolean
  booking?: AccountAppointment
}

export { AccountAppointment }
