import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { useLoading } from '@data-client/hooks'
import { useCache, useController } from '@data-client/react'

import { AccountResource } from '../api/account'
import { AccountEntity, type AccountFlag, type AccountIsRegistered } from '../datasource/account/account'
import { useNotification } from '../providers/Notification'

type ContextProps = {
  authenticate: (credentials: API.LoginRequest) => Promise<AccountEntity | null>
  validate: (username: string) => Promise<AccountIsRegistered | undefined>
  authenticated: boolean | undefined
  logout: () => void
  user?: AccountEntity | null
  refreshAccount: () => void
  flag?: AccountFlag
}

const defaultValues: ContextProps = {
  authenticate: async () => null,
  validate: async (username: string) => undefined,
  authenticated: undefined,
  logout: async () => undefined,
  refreshAccount: async () => undefined,
}

const Auth = createContext<ContextProps>(defaultValues)

function AuthProvider({ children }: PropsWithChildren) {
  const { invalidateAll, fetch } = useController()
  const { notifyOnError } = useNotification()
  const cached = useCache(AccountResource.me)
  const [user, setUser] = useState<AccountEntity | undefined>(cached)
  const [authenticated, setAuthenticated] = useState<boolean>()

  const validate = useCallback(
    async (username: string): Promise<AccountIsRegistered | undefined> => {
      return await fetch(AccountResource.registered, { username: username }).catch(() => undefined)
    },
    [fetch],
  )

  const refreshAccount = useCallback(async () => {
    await fetch(AccountResource.me)
      .then(setUser)
      .then(() => setAuthenticated(true))
      .catch(() => setAuthenticated(false))
  }, [fetch])

  const [handleLogin, loading] = useLoading(refreshAccount, [])

  const logout = useCallback(async () => {
    await fetch(AccountResource.logout)
      .then(() => invalidateAll({ testKey: (key) => !key.startsWith('/config') }))
      .then(() => {
        setUser(undefined)
        setAuthenticated(false)
      })
      .then(() => window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'LOGOUT' })))
  }, [fetch, invalidateAll])

  const authenticate = useCallback(
    async (credentials: API.LoginRequest) => {
      return await fetch(AccountResource.login, credentials)
        .then((account) => {
          setUser(account)
          setAuthenticated(true)
          window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'customerNumber', data: account?.pk() }))
          return account
        })
        .catch((error) => {
          notifyOnError(error)
          setUser(undefined)
          setAuthenticated(false)

          return null
        })
    },
    [fetch, notifyOnError],
  )

  useEffectOnce(() => {
    handleLogin().then()
  })

  return (
    <Auth.Provider
      value={{
        authenticate,
        validate,
        authenticated,
        user,
        refreshAccount,
        logout,
        flag: user?.flag,
      }}
    >
      {children}
    </Auth.Provider>
  )
}

const SessionProvider = AuthProvider

function useAuth(): ContextProps {
  return useContext(Auth)
}

export { Auth, AuthProvider, useAuth }

export default SessionProvider
