import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLoading } from '@data-client/hooks'
import { useController } from '@data-client/react'

import { AccountResource } from '@peoplevine/sdk/api/account'
import Form, { Button, Item } from '@peoplevine/sdk/components/form'
import { FormItem } from '@peoplevine/sdk/components/form/FormItem'
import { VerticalSpace } from '@peoplevine/sdk/components/layout'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { useNotification } from '@peoplevine/sdk/providers/Notification'
import routes from '@peoplevine/sdk/routes'

interface IActivateAccount {
  code: string
  customerId: number
}

const ActivateAccount: FC<IActivateAccount> = ({ code, customerId }) => {
  const { fetch } = useController()
  const navigate = useNavigate()
  const { notifyOnError, notify } = useNotification()
  const [enabled, setEnabled] = useState(false)

  const handleActivate = useCallback(
    async (value: { password: string }) => {
      await fetch(AccountResource.activate, { code: code, password: value.password, customerId: customerId })
        .then(() => {
          notify({
            message: 'Account Activated',
            description:
              'Your account has been successfully activated, you can login to your account using your new password',
          })
          navigate(routes.userLogin)
        })
        .catch(notifyOnError)
    },
    [code, customerId, fetch, navigate, notify, notifyOnError],
  )

  const [activate, activating] = useLoading(handleActivate, [])

  return (
    <VerticalSpace size={'large'}>
      <VerticalSpace>
        <Title level={1}>Set Your Password</Title>
        <Text type={'secondary'}>Let's secure your account with a new password.</Text>
      </VerticalSpace>
      <Form<{ password: string }> onFinish={activate}>
        <FormItem.Password onValidate={setEnabled} name={'password'} disabled={activating} showLevel />
        <Item>
          <Button icon={'directional/arrow-right'} loading={activating} disabled={!enabled} block htmlType={'submit'}>
            Continue
          </Button>
        </Item>
      </Form>
    </VerticalSpace>
  )
}

export default ActivateAccount
