import type { FC, ReactNode } from 'react'

import { Descriptions as BaseDescriptions } from 'antd'

import Text from '..//text/Text'
import { Title } from '../text/Title'

type DescriptionItem = { icon?: Design.IvyIcon; text: ReactNode }

type DescriptionsProps = { title: string } & Data.Source<DescriptionItem[]>

const Descriptions: FC<Partial<DescriptionsProps>> = ({ data = [], title }) => {
  return (
    <BaseDescriptions
      title={
        <Title rows={1} level={5}>
          {title}
        </Title>
      }
      column={1}
      size={'small'}
      colon={false}
      items={data
        .filter((it) => it.text)
        .map(({ icon, text }) => ({
          style: { paddingBottom: 4 },
          children:
            typeof text === 'string' ? (
              <Text icon={icon} type={'secondary'}>
                {text}
              </Text>
            ) : (
              text
            ),
        }))}
    />
  )
}

export { Descriptions, type DescriptionItem }
