import { createSlice } from '@reduxjs/toolkit'

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    groupVisible: false,
    activeGroup: '',
  },
  reducers: {
    setGroupVisible(state, action: { payload: boolean }) {
      state.groupVisible = action.payload
    },
    setActiveGroup(state, action: { payload: string }) {
      state.activeGroup = action.payload
    },
  },
})

export default chatSlice.reducer
