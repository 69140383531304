import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useConfig } from '../../providers/ConfigProvider'
import { useApp } from '../app'
import { useResponsive } from '../screen'

type LayoutState = {
  sider: { state: SDK.Components.SiderState; width: number; collapsedWidth: number; currentWidth: number }
  header: { hidden: boolean }
  footer: { hidden: boolean }
  navigation: { mode: SDK.Components.NavigationMode }
}

type ContextProps = Pick<LayoutState, 'navigation'> & {
  sider: {
    breakpoint: SDK.Components.ScreenSize
    collapsed: boolean
    hidden: boolean
    expand: VoidFunction
    hide: VoidFunction
    collapse: VoidFunction
    width: number
    collapsedWidth: number
    currentWidth: number
  }
  header: { hidden: boolean }
  footer: LayoutState['footer'] & { hide: VoidFunction }
}

const defaultValue: ContextProps = {
  sider: {
    breakpoint: 'lg',
    collapsed: false,
    hidden: false,
    expand: () => {},
    hide: () => {},
    collapse: () => {},
    collapsedWidth: 112,
    width: 280,
    currentWidth: 280,
  },
  header: { hidden: false },
  footer: {
    hidden: false,
    hide: () => {},
  },
  navigation: { mode: 'vertical' },
} as const

const LayoutContext = createContext(defaultValue)

const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    token,
    components: { ['Layout.Sider']: sider = { width: 280, collapsedWidth: 112 } },
  } = useApp()
  const { isTablet, isDesktop, isMobile } = useResponsive()
  const {
    portal: {
      props: {
        navigation: { mode = 'vertical' },
        sider: { collapsedWidth: siderCollapsedWidth = 112, width: siderWidth = 280 } = {
          width: sider.width,
          collapsedWidth: sider.collapsedWidth,
        },
        layout: { footer = true },
      },
    },
  } = useConfig()

  const [state, setState] = useState<ContextProps>({
    ...defaultValue,
    sider: {
      breakpoint: defaultValue.sider.breakpoint,
      collapsedWidth: siderCollapsedWidth,
      width: siderWidth,
      hidden: !isDesktop && !isTablet && mode !== 'vertical',
      collapsed: isTablet,
      currentWidth: isTablet ? sider?.collapsedWidth ?? 112 : isDesktop ? sider?.width ?? 280 : 0,
      expand: () => setSiderState('expanded'),
      hide: () => setSiderState('hidden'),
      collapse: () => setSiderState('collapsed'),
    },
    header: { hidden: mode !== 'horizontal' || isMobile },
    footer: {
      hidden: false,
      hide: () => setFooterState('hidden'),
    },
    navigation: { mode: mode ?? 'vertical' },
  })

  const setSiderState = useCallback(
    (state: SDK.Components.SiderState) => {
      const paddingVertical = isDesktop
        ? token.paddingContentVerticalLG
        : isMobile
          ? token.paddingContentVerticalSM
          : token.paddingContentVertical
      const paddingHorizontal = isDesktop
        ? token.paddingContentHorizontalLG
        : isMobile
          ? token.paddingContentHorizontalSM
          : token.paddingContentHorizontal

      setState((prevState) => ({
        ...prevState,
        sider: {
          ...prevState.sider,
          collapsed: state === 'collapsed',
          hidden: state === 'hidden',
          currentWidth: state === 'collapsed' ? siderCollapsedWidth : state === 'hidden' ? 0 : siderWidth,
        },
        style: {
          marginLeft: state === 'collapsed' ? siderCollapsedWidth : state === 'hidden' ? 0 : siderWidth,
          padding: `${paddingVertical}px ${paddingHorizontal}px`,
        },
      }))
    },
    [
      isDesktop,
      token.paddingContentVerticalLG,
      token.paddingContentVerticalSM,
      token.paddingContentVertical,
      token.paddingContentHorizontalLG,
      token.paddingContentHorizontalSM,
      token.paddingContentHorizontal,
      isMobile,
      siderCollapsedWidth,
      siderWidth,
    ],
  )

  const setFooterState = (state?: 'hidden') =>
    setState((prevState) => ({
      ...prevState,
      footer: { ...prevState?.footer, hidden: state === 'hidden' },
    }))

  useEffect(() => {
    if (mode) {
      setState((prevState) => ({
        ...prevState,
        navigation: { mode },
      }))
      if (mode !== 'vertical') setSiderState('hidden')
    }
  }, [mode, setSiderState])

  useEffect(() => {
    isDesktop && setSiderState('expanded')
    isTablet && setSiderState('collapsed')
    isMobile && setSiderState('hidden')
  }, [setSiderState, isTablet, isDesktop, isMobile])

  return <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
}

const useLayoutState = () => useContext(LayoutContext)

export { LayoutProvider, useLayoutState }
