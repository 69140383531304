import { Entity } from '@data-client/rest'

class PaginatedResponse<T> extends Entity {
  results: T[]
  pagination: PaginatedHeader

  pk(): undefined {
    return undefined
  }
}

class PaginatedHeader extends Entity implements API.Pagination, API.SortingParams {
  pageNumber: number = 1
  totalCount: number = 0
  pageSize: number = 3
  hasPreviousPage: boolean
  hasNextPage: boolean = false
  orderBy: string = ''
  pk(): undefined {
    return undefined
  }
}

export { PaginatedHeader, PaginatedResponse }
