import { createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'

class CompanyConfig extends ApiEntity implements Data.Address {
  readonly name: string = ''
  readonly logoUrl: string = ''
  readonly iconUrl: string = ''
  readonly biography: string = ''
  readonly website: string = ''
  readonly email: string = ''
  readonly phone: string = ''
  readonly address: string = ''
  readonly address2: string = ''
  readonly city: string = ''
  readonly state: string = ''
  readonly zipCode: string = ''
  readonly country: string = ''
  readonly timezone: string = ''
  readonly timezoneOffset: number = 0
  readonly backgroundColor: string = ''
  readonly fullAddress: string = ''
  type: API.AddressType = 'company'
}

const CompanyBaseResource = createApiResource({
  path: '/admin/api/company/:id',
  schema: CompanyConfig,
})

const CompanyResource = {
  ...CompanyBaseResource,
}

export { CompanyConfig, CompanyResource }
