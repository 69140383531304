import { FC, PropsWithChildren, ReactNode } from 'react'

import { Col, Divider, Flex, Row, Card as Wrapped } from 'antd'

import sanitize from 'sanitize-html-react'

import Text from '..//text/Text'
import { useColor } from '../app'
import { Image } from '../image/Image'
import { Skeleton } from '../skeleton/Skeleton'
import { Title } from '../text/Title'
import { CardBadge } from './CardBadge'

const Cover: FC<
  SDK.Components.ImageProps &
    Data.Loadable & {
      Component?: FC<SDK.Components.ImageProps>
      tags?: ReactNode
      badge?: ReactNode
    }
> = ({ Component = Image, tags, badge, ...props }) => {
  const { token } = useColor()
  const height = Math.floor((token.size * 24 * 9) / 16)

  return (
    <div
      style={{
        height,
        width: `calc(100% - 2px)`,
        boxShadow: 'none',
        margin: 1,
        borderTopRightRadius: token.borderRadius,
        borderTopLeftRadius: token.borderRadius,
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '0 16px 0 24px',
          position: 'absolute',
          top: Math.floor(Number(height) - (64 * 3) / 4 - 4),
          zIndex: 2,
        }}
      >
        {badge && <CardBadge>{badge}</CardBadge>}
      </div>
      <Component
        style={{ borderTopRightRadius: token.borderRadius, borderTopLeftRadius: token.borderRadius, border: 'none' }}
        height={height}
        {...props}
      />
    </div>
  )
}

const Card: FC<PropsWithChildren<Partial<SDK.Components.CardProps>>> = ({
  style,
  title,
  subTitle,
  description,
  defaultTitle = '',
  meta,
  loading = false,
  hideDescription = false,
  type = 'primary',
  onClick = undefined,
  active = true,
  image,
  media,
  badge = null,
  bordered = false,
  children,
  className = '',
  shape = 'circle',
  equalHeight,
  footer,
  tags = null,
  footerBordered = true,
  footerFlush = false,
  flush = false,
  ...props
}) => {
  const { token } = useColor()
  return (
    <Wrapped
      className={onClick ? 'hoverable' : ''}
      onClickCapture={onClick}
      bordered={true}
      hoverable={false}
      styles={{ body: { height: `calc(100% - 216px)`, padding: 24 } }}
      cover={
        typeof image === 'object' ? (
          <Cover height={216} badge={badge} loading={loading} Component={Image} {...image} />
        ) : (
          image
        )
      }
      style={{
        width: '100%',
        height: '100%',
        cursor: onClick ? 'pointer' : 'initial',
        boxShadow: 'none',
        ...style,
        borderRadius: token.borderRadius,
        borderColor: `${token.colorBorder}`,
      }}
      {...props}
    >
      <Flex
        gap={12}
        vertical
        align={'stretch'}
        justify={type === 'primary' ? 'start' : 'center'}
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Flex gap={12} justify={'start'} vertical>
          {(title && typeof title === 'string') || loading ? (
            <Title translate={'no'} loading={loading} level={5}>
              {title}
            </Title>
          ) : (
            title
          )}
          {(subTitle && typeof subTitle === 'string') || loading ? (
            <Text translate={'no'} font={{ weight: 600 }} loading={loading} level={5}>
              {subTitle}
            </Text>
          ) : (
            subTitle
          )}
          {tags && <Flex gap={4}>{loading ? <Skeleton width={50} /> : tags}</Flex>}
          {!hideDescription &&
            description &&
            (loading ? (
              <>
                <Skeleton count={1} width={'75%'} height={token.fontSizeLG} />
                <Skeleton count={1} width={'65%'} height={token.fontSizeLG} />
              </>
            ) : (
              <Text type={'secondary'} translate={'no'} rows={3}>
                {typeof description === 'string'
                  ? sanitize(description, {
                      allowedTags: [],
                      parser: { decodeEntities: true },
                    })
                  : description}
              </Text>
            ))}
        </Flex>
        <Flex gap={8} vertical align={'stretch'}>
          <Row gutter={[0, 8]} align={'bottom'}>
            <Col span={24}>{loading ? <Skeleton count={2} width={'100%'} height={token.fontSizeSM} /> : meta}</Col>
            {footer && <Divider style={{ margin: '8px 0' }} type={'horizontal'} />}
            {children && (
              <Col span={24}>{loading ? <Skeleton width={'100%'} height={token.fontSizeSM} /> : children}</Col>
            )}
            {footer && (
              <Col span={24}>{loading ? <Skeleton height={token.controlHeight} width={'100%'} /> : footer}</Col>
            )}
          </Row>
        </Flex>
      </Flex>
    </Wrapped>
  )
}
export type CardProps = SDK.Components.CardProps
export { Card }
