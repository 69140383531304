import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex, Input, notification } from 'antd'

import { useLoading } from '@data-client/hooks'
import { useController } from '@data-client/react'

import { Button } from '@peoplevine/sdk/components/button/Button'
import Form from '@peoplevine/sdk/components/form'
import { VerticalSpace } from '@peoplevine/sdk/components/layout'
import { StepsSlider } from '@peoplevine/sdk/components/steps/StepsSlider'
import { Link } from '@peoplevine/sdk/components/text'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { VerifyResource } from '@peoplevine/sdk/datasource/verify'
import { withPrefix } from '@peoplevine/sdk/providers/ConfigProvider'
import { useNotification } from '@peoplevine/sdk/providers/Notification'
import routes from '@peoplevine/sdk/routes'

import ActivateAccount from '../ActivateAccount'

interface IVerifySMSCode {
  customerId: number
}

type VerifyResponse = {
  success: boolean
  code: string
  message: string
}

const VerifySMSCode: FC<IVerifySMSCode> = ({ customerId }) => {
  const CODE_LENGTH = 5
  const { fetch } = useController()
  const navigate = useNavigate()
  const { notifyOnError, notify } = useNotification()
  const [enabled, setEnabled] = useState(false)
  const [code, setCode] = useState('')
  const [activeStep, setActiveStep] = useState(0)

  const handleCodeError = (errorCode: string, errorMessage: string) => {
    let message = 'Invalid Activation Code'
    let description: ReactNode = errorMessage
    if (errorCode === 'activation_code_expired') {
      message = 'Activation Code Expired'
      description = (
        <Flex gap={2}>
          <Text>This activation code is expired.</Text>
          <Link
            onClick={() => {
              notification.destroy('activation-code-error')
              navigate(routes.userLogin)
            }}
          >
            Click here to restart the activation process
          </Link>
        </Flex>
      )
    }
    notify({
      type: 'warning',
      key: 'activation-code-error',
      message,
      description,
    })
  }

  const handleVerify = useCallback(async () => {
    await fetch(VerifyResource.activation.verify, { customerId, code })
      .then<VerifyResponse>((response) => response.json())
      .then((response) => {
        if (!response.success) {
          handleCodeError(response.code, response.message)
        } else {
          setActiveStep(1)
        }
      })
      .catch(notifyOnError)
  }, [code, customerId, fetch, handleCodeError, notifyOnError])
  const [verifyCode, loading] = useLoading(handleVerify, [])

  useEffect(() => {
    setEnabled(!!(code && code.length === CODE_LENGTH))
  }, [code])

  return (
    <StepsSlider
      active={activeStep}
      elements={[
        <VerticalSpace key={0} size={16}>
          <Title level={1} style={{ marginBottom: 0 }}>
            Activate Account
          </Title>
          <Flex vertical gap={2}>
            <Text>We've sent a 5-digit code to your mobile number</Text>
            <Text>Enter the code below</Text>
          </Flex>
          <Form onFinish={verifyCode}>
            <div className={withPrefix('verify-sms-code-wrapper')}>
              <div className={withPrefix('verify-sms-code-container')}>
                <Input
                  type={'numeric'}
                  autoComplete={'one-time-code'}
                  disabled={loading}
                  maxLength={CODE_LENGTH}
                  className={withPrefix('input', 'verify-sms-code-input')}
                  onChange={(evt) => setCode(evt.target.value)}
                  placeholder={'_ _ _ _ _'}
                />
              </div>
              <Button loading={loading} block htmlType={'submit'} disabled={!enabled}>
                Verify code
              </Button>
            </div>
          </Form>
        </VerticalSpace>,
        <ActivateAccount key={1} code={code} customerId={customerId} />,
      ]}
    />
  )
}

export default VerifySMSCode
