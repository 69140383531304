import { FC, PropsWithChildren, createContext, useContext } from 'react'
import { useIntl } from 'react-intl'

import { Spin } from 'antd'

import { AsyncBoundary, useSuspense } from '@data-client/react'

import classNames from 'classnames'

import { Result } from '../components/result'
import defaultConfig from '../configs'
import { Config, ConfigResource, PageConfig } from '../datasource/config'

const Context = createContext<Pick<Config, 'portal' | 'company'>>(defaultConfig)

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const data = useSuspense(ConfigResource.handshake)
  if (!data) return <Result.ConfigurationError />
  return <Context.Provider value={data}>{children}</Context.Provider>
}

export const useConfig = () => useContext(Context)

export type IntlFormatter = {
  currency: {
    (value: number): string
    (value: number | bigint): string
  }
  message: (id: string, defaultMessage: string) => string
}

export const useIntlFormatter: () => IntlFormatter = () => {
  const { formatMessage, formatNumber } = useIntl()
  return {
    currency: (value: number | bigint) => formatNumber(value, { format: 'currency' }),
    message: (id: string, defaultMessage: string) => formatMessage({ id, defaultMessage: defaultMessage }),
  }
}

type MenuConfigProps = {
  pages: PageConfig[]
  pageByUri: (uri: string) => PageConfig | undefined
  pageNameByUri: (uri: string) => string
}

export const useMenuConfig: () => MenuConfigProps = () => {
  const { portal } = useConfig()
  const pages = portal.pages.filter((page) => page.enabled).sort((a, b) => a.order - b.order)

  function pageByUri(uri = '') {
    const pathname = !uri.startsWith('/') ? `/${uri}` : uri
    return pages.find((it) => it.uri === pathname)
  }

  const pageNameByUri = (uri = '') => pageByUri(uri)?.name ?? ''
  return { pages, pageNameByUri, pageByUri }
}

export const withPrefix: (...names: string[]) => string = (...names) => {
  const key = 'ant'
  return classNames(
    names.filter((value) => !!value.trim()).map((name) => (!name.startsWith(key) ? `${key}-${name} ` : name)),
  ).trim()
}

const ConfigProvider: FC<PropsWithChildren> = ({ children }) => (
  <AsyncBoundary fallback={<Spin size={'small'} fullscreen />}>
    <Provider>{children}</Provider>
  </AsyncBoundary>
)
export { ConfigProvider, Context }
