import { FC, PropsWithChildren, ReactNode } from 'react'

import { Form as AbstractForm, FormItemProps as AntFormItemProps, FormRule } from 'antd'

import { FieldType } from '../../datasource/survey'
import Text from '..//text/Text'
import { useColor } from '../app'
import { Button } from '../button/Button'
import { DatePicker } from './DatePicker'
import { Form } from './Form'
import { InputNumber } from './InputNumber'
import { RadioSelect } from './RadioSelect'
import { TimePicker } from './TimePicker'

type FormItemProps<T = any> = {
  type?: FieldType
  label?: ReactNode
  placeholder?: string
  isLabelTitle?: boolean
} & AntFormItemProps<T>

const Label: FC<PropsWithChildren<{ hasTooltip?: boolean; isLabelTitle?: boolean; element?: string; form?: string }>> = ({
  hasTooltip,
  isLabelTitle = false,
  children,
}) => {
  const { token, components, color } = useColor()
  return typeof children === 'string' ? (
    <Text
      translate={'yes'}
      type={isLabelTitle? 'default' : 'secondary'}
      font={{ size: isLabelTitle ? token.fontSize : components.Form?.labelFontSize, weight: 400 }}
      style={{
        width: '100%',
        display: 'inline-block',
        padding: `0 ${hasTooltip ? 32 : 4}px 0 4px`,
        marginRight: hasTooltip ? -28 : undefined,
        marginLeft: -2,
        lineHeight: 1,
        backgroundColor: color(token.colorBgLayout)?.hex(),
        borderRadius: token.borderRadius,
      }}
    >
      {children}
    </Text>
  ) : (
    children
  )
}

const Item: FC<FormItemProps> = ({ type, label, isLabelTitle = false, tooltip, placeholder, labelCol, ...props }) => {
  const { token } = useColor()
  return (
    <AbstractForm.Item
      data-cy={props.name}
      style={{ width: '100%', marginBottom: 8, padding: '8px 0' }}
      labelCol={{
        style: !!label
          ? isLabelTitle
            ? {}
            : {
              position: 'relative',
              zIndex: 2,
              height: Math.floor(token.fontSize * token.lineHeight),
              marginLeft: 8,
              left: 8,
              top: Math.floor((token.fontSize * token.lineHeight) / 2) + 1,
            }
          : { display: 'none' },
      }}
      wrapperCol={{
        span: 24,
      }}
      label={<Label isLabelTitle={isLabelTitle} hasTooltip={!!tooltip}>{label}</Label>}
      tooltip={tooltip}
      {...props}
    />
  )
}

const List = AbstractForm.List
const useForm = AbstractForm.useForm

export { Switch } from './Switch'
export {
  Form as default,
  type AntFormItemProps as FormProps,
  type FormItemProps,
  type FormRule,
  useForm,
  Button,
  Form,
  Item,
  List,
  DatePicker,
  InputNumber,
  TimePicker,
  RadioSelect,
}
