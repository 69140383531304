import { Entity } from '@data-client/rest'

import { ImageEntity } from '../../media'
import { Order } from '../../orders/order'
import { TransactionRefundItem } from '../../transaction'
import { AccountTicket } from '../ticket'
import { AccountBooking } from './booking'

class AccountEvent extends AccountBooking {
  static urlRoot = `/api/account/events`

  readonly summary: string = ''
  readonly venue: string = ''
  readonly venueAddress?: string
  readonly graphic: string = ''
  readonly photos: ImageEntity[] = []
  readonly type: API.ScheduleItemType = 'event'
  readonly purchasedTickets: AccountTicket[] = []
  readonly timezone_id: string = ''
  readonly uid: string = ''
  readonly registered: boolean = false
  readonly settings: API.EventSettings = {
    transferEnabled: false,
    cancelEnabled: false,
    socialEnabled: false,
    printEnabled: false,
    calendarEnabled: false,
    buttonText: '',
    callToAction: '',
    verb: '',
  }

  get activeTickets(): AccountTicket[] {
    return this.purchasedTickets.filter((t) => t.status !== 'cancelled' && t.status !== 'scanned')
  }

  get ticketQty(): number {
    return this.activeTickets ? this.activeTickets.length : 0
  }

  get transferredQty(): number {
    return this.activeTickets.filter((p) => p.customerId !== p.ownerId).length
  }
}

class AccountEventCancelResponse extends Entity {
  readonly orderId?: Data.ID
  readonly order?: Order
  readonly refunds?: TransactionRefundItem[]
  readonly amount: number = 0
  readonly message: string = ''
  readonly success: boolean = false
  readonly booking?: AccountEvent
  readonly orders?: Order[]

  pk() {
    return `${this.orderId}`
  }
}

export { AccountEvent, AccountEventCancelResponse }
