import { FC, type SVGProps } from 'react'
import { ReactSVG } from 'react-svg'

import classNames from 'classnames'
import 'flag-icons/css/flag-icons.min.css'

import { useColor } from '../app'

const action: Record<string, FC<SVGProps<SVGSVGElement>>> = import.meta.glob('./assets/action/*.svg', {
  eager: true,
  import: 'default',
  as: 'react',
})
const custom: Record<string, FC<SVGProps<SVGSVGElement>>> = import.meta.glob('./assets/custom/*.svg', {
  eager: true,
  import: 'default',
  as: 'react',
})
const application: Record<string, FC<SVGProps<SVGSVGElement>>> = import.meta.glob('./assets/application/*.svg', {
  eager: true,
  import: 'default',
  as: 'react',
})
const brand: Record<string, FC<SVGProps<SVGSVGElement>>> = import.meta.glob('./assets/brand/*.svg', {
  eager: true,
  import: 'default',
  as: 'react',
})
const symbol: Record<string, FC<SVGProps<SVGSVGElement>>> = import.meta.glob('./assets/symbol/*.svg', {
  eager: true,
  import: 'default',
  as: 'react',
})
const paymentBrand: Record<string, FC<SVGProps<SVGSVGElement>>> = import.meta.glob('./assets/payment-brand/*.svg', {
  eager: true,
  import: 'default',
  as: 'react',
})
const SvgIcon: FC<Omit<SDK.Components.IconProps, 'type'> & { Component?: FC<SVGProps<SVGSVGElement>> }> = ({
  Component,
  color,
  style,
  size = 16,
  ...props
}) => {
  const { textByVariant } = useColor()

  return (
    Component && (
      <span
        style={{
          ...style,
          fontSize: size,
          padding: 0,
          lineHeight: 1,
          width: size,
          color: color ? textByVariant(color) : undefined,
        }}
        className={'anticon'}
        {...props}
      >
        <Component width={'1em'} height={'1em'} {...props} />
      </span>
    )
  )
}
const IvyIcon: FC<SDK.Components.IconProps> = ({ type, size, color, style }) => {
  const { textByVariant } = useColor()
  if (type?.startsWith('flags/'))
    return <span className={classNames('fi', `fi-${type.replace('flags/', '').toLowerCase()}`)} />
  if (type.startsWith('action')) {
    return <SvgIcon Component={action[`./assets/${type}.svg`]} color={color} style={style} size={size} />
  }
  if (type.startsWith('application')) {
    return <SvgIcon Component={application[`./assets/${type}.svg`]} color={color} style={style} size={size} />
  }
  if (type.startsWith('custom')) {
    return <SvgIcon Component={custom[`./assets/${type}.svg`]} color={color} style={style} size={size} />
  }
  if (type.startsWith('brand')) {
    return <SvgIcon Component={brand[`./assets/${type}.svg`]} color={color} style={style} size={size} />
  }
  if (type.startsWith('symbol')) {
    return <SvgIcon Component={symbol[`./assets/${type}.svg`]} color={color} style={style} size={size} />
  }
  if (type.startsWith('payment-brand')) {
    return <SvgIcon Component={paymentBrand[`./assets/${type}.svg`]} color={color} style={style} size={size} />
  }
  return (
    <ReactSVG
      className={'anticon'}
      style={{
        ...style,
        fontSize: size,
        padding: 0,
        lineHeight: 1,
        width: 18,
        color: color ? textByVariant(color) : undefined,
      }}
      wrapper={'span'}
      src={`/assets/icons/${type}.svg`}
      afterInjection={(svg) => {
        svg.setAttribute('width', '1em')
        svg.setAttribute('height', '1em')
      }}
    />
  )
}

export default IvyIcon
