import { useWindowSize } from 'react-use'

enum BreakpointWidth {
  XS = 375,
  SM = 576,
  MD = 768,
  LG = 1200,
  XL = 1600,
  XXL = 1800,
}
function spanByBreakpoint(width: number, size: SDK.Components.ScreenSize) {
  const siderWidth = size === 'xs' || size === 'sm' ? 0 : size === 'md' || size === 'lg' ? 112 : 280
  return Math.floor((width - siderWidth - 16 * 2) / (16 * 24))
}

type Breakpoint = SDK.Components.BreakpointMap<Uppercase<SDK.Components.ScreenSize>>

const DesignBreakpoints: SDK.Components.BreakpointMap<SDK.Components.ScreenSize> = {
  xs: BreakpointWidth.XS,
  sm: BreakpointWidth.SM,
  md: BreakpointWidth.MD,
  lg: BreakpointWidth.LG,
  xl: BreakpointWidth.XL,
  xxl: BreakpointWidth.XXL,
} as const

const DeviceBreakpoint: SDK.Components.BreakpointMap<SDK.Components.Device> = {
  mobile: BreakpointWidth.SM,
  tablet: BreakpointWidth.MD,
  desktop: BreakpointWidth.LG,
} as const

const Breakpoint: Breakpoint = Object.entries(DesignBreakpoints).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key.toUpperCase()]: value,
  }),
  {} as Breakpoint,
)

const BreakpointMin: Breakpoint = Breakpoint

const BreakpointMax: Breakpoint = Object.entries(Breakpoint).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: value - 1 }),
  Breakpoint,
)

/**
 * Convert width to breakpoint
 * @param {number} width
 */
function getBreakpoint(width: number): SDK.Components.ScreenSize {
  if (Breakpoint.SM >= width && Breakpoint.XS < width) return 'sm'
  if (Breakpoint.MD >= width && Breakpoint.SM < width) return 'md'
  if (Breakpoint.LG >= width && Breakpoint.MD < width) return 'lg'
  if (Breakpoint.XL >= width && Breakpoint.LG < width) return 'xl'
  return Breakpoint.XS >= width ? 'xs' : 'xxl'
}

/**
 * Use this hook to get current breakpoint and device type
 */
const useResponsive: SDK.Components.UseResponsiveHook = () => {
  const { width: currentWidth } = useWindowSize()
  const isMobile = currentWidth <= DeviceBreakpoint.mobile
  const isDesktop = currentWidth > DeviceBreakpoint.desktop
  const isTablet = currentWidth > DeviceBreakpoint.mobile && currentWidth < DeviceBreakpoint.desktop
  const isNative = !!window?.ReactNativeWebView
  const currentBreakpoint = getBreakpoint(currentWidth)
  const siderWidth = isMobile ? 0 : isTablet ? 112 : 280
  const gridSize = isMobile ? 1 : isTablet ? 2 : 3
  const currentDevice: SDK.Components.Device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'
  return {
    breakpoint: currentBreakpoint,
    device: currentDevice,
    isNative,
    isMobile,
    isDesktop,
    isTablet,
    gridSize,
    isDevice: (type: SDK.Components.Device) =>
      type === 'mobile' ? isMobile : type === 'tablet' ? isTablet : isDesktop,
    satisfies: ({ breakpoint }) => {
      if (isDesktop) return breakpoint === 'xxl' || breakpoint === 'xl'
      if (isMobile) return breakpoint === 'sm' || breakpoint === 'xs'
      if (isTablet) return breakpoint === 'md' || breakpoint === 'lg'
      return false
    },
  }
}

export { Breakpoint, BreakpointMin, BreakpointMax, useResponsive }
