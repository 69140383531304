import { CSSProperties, FC, PropsWithChildren, type SVGProps } from 'react'

import { Flex, Spin } from 'antd'

const Icon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg viewBox='0 0 1024 1024' focusable='false' {...props}>
    <path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z' />
  </svg>
)

const Spinner: FC<PropsWithChildren<{ fullscreen?: boolean; style?: CSSProperties } & Data.Loadable>> = ({
  loading,
  children,
  fullscreen,
  style,
}) => (
  <Flex style={{ height: fullscreen ? '100dvh' : '100%', width: '100%' }} vertical>
    <Spin
      style={{
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'visible',
        margin: 'auto',
        ...style,
      }}
      spinning={loading}
      size={'small'}
    >
      {children}
    </Spin>
  </Flex>
)

const SectionLoader = Spinner

const OverlayLoader = Spinner

export { OverlayLoader, SectionLoader, Spinner, Icon }
