import { CSSProperties, FC, type MouseEventHandler } from 'react'

import { Flex, ImageProps } from 'antd'

import { useConfig, withPrefix } from '../../providers/ConfigProvider'

export type LogoProps = ImageProps & {
  type: API.LogoType
  dark?: boolean | undefined
  style?: CSSProperties
  strict?: boolean
}
export const LogoImage: FC<ImageProps> = ({ style, ...props }) => (
  <img src={props.src} style={{ objectFit: 'contain', objectPosition: '50% 50%', ...style }} onClick={props.onClick} />
)

type SizeProps<T extends 'text' | 'icon' | string = string> = Pick<CSSProperties, 'height' | 'width'> &
  Record<T, number | undefined>

type LogoComponentProps<T extends 'text' | 'icon' | 'image' | string = string> = {
  size: Partial<SizeProps<T>>
  onClick?: MouseEventHandler
} & Record<T, string>

const IconLogo: FC<LogoComponentProps<'icon'>> = ({
  onClick,
  icon,
  size: { icon: iconSize = 48, height = '100%', width },
}) => (
  <LogoImage
    onClick={onClick}
    src={icon}
    style={{
      height: height,
      width: width,
    }}
  />
)

const TextLogo: FC<LogoComponentProps<'text'>> = ({ onClick, text, size: { text: textSize, height, width } }) => (
  <LogoImage
    onClick={onClick}
    src={text}
    className={withPrefix('logo-text')}
    style={{
      height,
      width,
      maxHeight: `${textSize ?? 100}%`,
      maxWidth: `${textSize ?? 100}%`,
    }}
  />
)

const IconTextLogo: FC<LogoComponentProps<'icon' | 'text'>> = ({
  icon,
  text,
  size: { icon: iconSize, text: textSize, height = 36, width },
  onClick,
}) => (
  <Flex onClick={onClick} gap={16} style={{ height, width, cursor: 'pointer' }} align={'center'} justify={'center'}>
    {icon && <IconLogo icon={icon} size={{ icon: iconSize }} />}
    {text && <TextLogo size={{ text: textSize }} text={text} />}
  </Flex>
)

const FullLogo: FC<LogoComponentProps<'image'>> = ({ onClick, image: src, size: { height, width = '100%' } }) => (
  <LogoImage
    onClick={onClick}
    src={src}
    style={{
      height,
      width,
    }}
  />
)

export const Logo: FC<Partial<LogoProps>> = ({
  type = 'icon',
  onClick,
  width,
  height,
  dark = true,
  strict = false,
  style,
}) => {
  const {
    portal: {
      props: {
        logo,
        layout: { theme },
      },
    },
  } = useConfig()

  const logoVersion = dark !== undefined ? (dark ? 'standard' : 'inverted') : theme !== 'dark' ? 'standard' : 'inverted'

  const { full, text, icon, fullSize, textSize, iconSize = 16 } = logo[logoVersion] || {}

  // Determine if a fallback logo is allowed to be used
  if (type === 'icon' && !icon) {
    if (strict) return null
    type = 'text'
  } else if (type === 'text' && !text) {
    if (strict) return null
    type = 'icon'
  }

  switch (type) {
    case 'text':
      return text && <TextLogo onClick={onClick} text={text} size={{ height, width, text: textSize }} />
    case 'icon':
      return icon && <IconLogo icon={icon} onClick={onClick} size={{ height, width, icon: iconSize }} />
    case 'full':
      return (
        full && <FullLogo onClick={onClick} image={full} size={{ height, width: width ?? `${fullSize ?? 100}%` }} />
      )
    case 'icon+text':
      return (
        icon &&
        text && (
          <IconTextLogo
            onClick={onClick}
            icon={icon}
            text={text}
            size={{ text: textSize, icon: iconSize, height, width }}
          />
        )
      )
  }
}
