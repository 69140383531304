import { Provider as ReduxProvider } from 'react-redux'

import { CacheProvider } from '@data-client/react'

import { AppProvider } from '@peoplevine/sdk/components/app/Provider'
import { ConfigProvider, FeatureProvider, NativeAppProvider } from '@peoplevine/sdk/providers'

import RoutesProvider from '../containers/routing'
import { store } from '../store'

const RootProvider = () => (
  <NativeAppProvider>
    <CacheProvider>
      <ReduxProvider store={store}>
        <ConfigProvider>
          <FeatureProvider>
            <AppProvider>
              <RoutesProvider />
            </AppProvider>
          </FeatureProvider>
        </ConfigProvider>
      </ReduxProvider>
    </CacheProvider>
  </NativeAppProvider>
)

export default RootProvider
