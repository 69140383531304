import { Entity, schema } from '@data-client/endpoint'
import { NetworkError } from '@data-client/react'

import dayjs from '../services/dayjs'

class TemporalEntity implements Data.TemporalLocal {
  startDateLocal: DateTz
  endDateLocal: DateTz

  startDate = (iso: string) => dayjs(iso).toDate()

  endDate = (iso: string) => dayjs(iso).toDate()
}

class DateTz extends schema.Entity(Entity) implements Data.DateTz {
  static schema = {
    date: (iso: string) => (iso ? dayjs(iso).toDate() : null),
  }
  date: Date = new Date()
  name: string = ''
  timezone: string = ''
  offset: string = ''

  get dayjs() {
    return dayjs(this.date)
  }

  format(as: string) {
    if (this.timezone) return this.dayjs.tz(this.timezone)?.utc(true).format(as)
    if (this.offset) return this.dayjs.utcOffset(this.offset)?.utc(true).format(as)
    return this.dayjs.format(as)
  }

  pk(): string {
    return this.date.toString()
  }
}

class DateRangeEntity {
  start: dayjs.Dayjs
  end: dayjs.Dayjs

  constructor(...date: (dayjs.Dayjs | Date | DateTz)[]) {
    const [from, to = from] = date
    this.start = dayjs(from instanceof DateTz ? DateTz.fromJS(from).dayjs : from)
    this.end = dayjs(to instanceof DateTz ? DateTz.fromJS(to).dayjs : to)
  }

  get from() {
    return this.start
  }

  get to() {
    return this.end
  }

  get dayjs() {
    return {
      to: dayjs(this.end),
      from: dayjs(this.start),
    }
  }

  pk() {
    return undefined
  }
}

const ErrorTitles = {
  invalid_request: {
    title: 'Invalid Request',
  },
  ticket_max_reached: {
    title: 'Maximum Tickets Purchased',
  },
  not_deleted: {
    title: 'There was an error',
  },
  invalid_coupon: {
    title: 'Invalid Coupon',
  },
  wallet_device_not_supported: {
    title: 'Device Not Supported',
    message: 'This device cannot be used to download a wallet pass.  Supported devices are Android and iOS',
  },
}
type ErrorData = {
  requestId?: string
  error: ErrorCode
  errorDescription: string
}
type ErrorCode = 'credit_card_invalid' | string

class ApiError implements NetworkError {
  name = 'ApiError'
  message: string
  status: number
  error?: string
  requestId?: string

  constructor(
    { data, ...response }: Data.Source<Partial<API.ErrorResponse>> & Partial<Response>,
    cause?: Partial<Error>,
  ) {
    const { status = 500 } = response
    console.log(data)
    const { title = 'API Error', error, error_description: description = '', requestId = '' } = data
    this.error = error
    this.name = title
    this.status = status
    this.message = description
    this.requestId = requestId
  }
}

export { DateTz, TemporalEntity, DateRangeEntity, ApiError }
