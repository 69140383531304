import { FC } from 'react'
import { useResponsive } from './Breakpoint'

/**
 * Use this component to render children only on specific device type
 * Breakpoints take precedence over device types, if both are provided
 */
const RenderOn: FC<SDK.Components.DeviceRenderProps & { and?: boolean[] }> = ({
  not = false,
  children,
  breakpoints = [],
  desktop = false,
  mobile = false,
  tablet = false,
  native = false,
  and: conditions = [true],
}) => {
  const { device, satisfies } = useResponsive()
  let shouldRender: boolean = conditions?.every(Boolean)
  if (native && !!window?.ReactNativeWebView) return children
  if (breakpoints.length) {
    shouldRender = breakpoints.some((it: SDK.Components.ScreenSize) => satisfies({ breakpoint: it }))
  } else {
    const devices = { mobile, tablet, desktop }
    shouldRender = devices[device]
  }

  return (shouldRender && !not) || (!shouldRender && not) ? children : null
}

export { Breakpoint as default } from './Breakpoint'
export { RenderOn, useResponsive }
