import { type FC, type PropsWithChildren, useCallback, useState } from 'react'

import { ConfigProvider as AntProvider, App } from 'antd'
import enUS from 'antd/locale/en_US'
import jaJP from 'antd/locale/ja_JP'

import merge from 'deepmerge'

import {
  Context,
  type ContextProps,
  defaultValue,
  resolveComponents,
  resolveStyles,
  resolveToken,
  withConfigColors,
} from '.'
import defaultTheme from '../../configs/portal/theme.json'
import { FontLoader, MetaProvider, useConfig, useI18n } from '../../providers'

const locales = { 'en-US': enUS, 'ja-JP': jaJP }

const AppProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const {
    portal: { theme: portalTheme = defaultTheme },
  } = useConfig()
  const { locale } = useI18n()
  const { token: overrideToken, components: overrideComponents } = withConfigColors({ ...portalTheme })
  const initialValue: {
    token: SDK.Components.ThemeToken
    components: SDK.Components.ComponentsConfig
  } = {
    token: resolveToken(merge(defaultValue.token, overrideToken)),
    components: merge(
      defaultValue.components,
      merge(resolveComponents(resolveToken(overrideToken)), overrideComponents),
    ),
  }
  const [theme, setTheme] = useState(initialValue)

  const setTokenValue: ContextProps['setTokenValue'] = useCallback(
    (value) => {
      setTheme(({ token: prevToken, components: prevComponents } = { components: {}, token: {} }) => {
        const token = resolveToken(merge(prevToken, value))
        const components = merge(prevComponents, resolveComponents(token))
        return { token, components }
      })
    },
    [setTheme],
  )

  return (
    <Context.Provider
      value={{
        ...theme,
        setTokenValue,
      }}
    >
      <MetaProvider>
        <FontLoader fonts={[theme?.token.fontFamily, theme?.token.fontFamilySecondary]} />
        <AntProvider
          locale={{ locale: locale.id }}
          componentSize={'middle'}
          theme={{ ...theme, cssVar: true }}
          {...resolveStyles(theme?.token)}
        >
          <App>{children}</App>
        </AntProvider>
      </MetaProvider>
    </Context.Provider>
  )
}

export { AppProvider }
