import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useTimeout } from 'react-use'

import { Col, ColProps, Row } from 'antd'

import { useColor } from '@peoplevine/sdk/components/app'
import { Logo, useLoginLogo, useLogoType } from '@peoplevine/sdk/components/logo'
import { LogoImage } from '@peoplevine/sdk/components/logo/Logo'
import { RenderOn } from '@peoplevine/sdk/components/screen'
import { useResponsive } from '@peoplevine/sdk/components/screen/Breakpoint'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { useConfig } from '@peoplevine/sdk/providers/ConfigProvider'

const LogoView: FC = () => {
  const { slogans } = useConfig().portal.props
  const { components, color } = useColor()
  const layoutBgColor = color(components?.Layout?.colorBgLayout)
  const hasImages = slogans?.some((slogan) => slogan?.image)
  const logoType = useLogoType()
  const loginLogo = useLoginLogo()
  return loginLogo ? (
    loginLogo.src ? (
      <LogoImage src={loginLogo.src} style={{ maxWidth: loginLogo?.width }} />
    ) : (
      <Logo dark={hasImages ? false : layoutBgColor.isDark()} type={logoType?.logo?.expanded ?? 'icon+text'} />
    )
  ) : (
    <Link to={'/'} style={{ overflow: 'hidden', display: 'flex', justifyContent: logoType.justify }}>
      <Logo dark={hasImages ? false : layoutBgColor.isDark()} type={logoType?.logo?.expanded ?? 'icon+text'} />
    </Link>
  )
}

const SlidesCol: FC<ColProps> = ({ ...colProps }) => {
  const {
    portal: {
      props: { slogans },
    },
  } = useConfig()
  const { isMobile } = useResponsive()

  const getRandomSlogan = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * slogans?.length)
    return slogans.filter((it) => (isMobile ? it.mobile : true))?.[randomIndex]
  }, [isMobile])

  const [currentSlide, setCurrentSlide] = useState(getRandomSlogan())
  const [showNextSlide, , reset] = useTimeout(30000)
  const showNext = showNextSlide()

  useEffect(() => {
    if (showNext === true) {
      setCurrentSlide(getRandomSlogan())
    }
    return reset
  }, [showNext, getRandomSlogan])

  return (
    <Col
      style={{
        height: '100%',
        backgroundImage: currentSlide?.image && `url(${currentSlide?.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...colProps}
    >
      {currentSlide && (
        <Row
          style={{
            alignContent: 'space-between',
            width: '100%',
            height: '90%',
            position: 'absolute',
            top: '50%',
            textShadow: '1px 1px 5px #212224',
            transform: 'translate(0, -50%)',
          }}
          gutter={[0, 40]}
          justify={'center'}
          align={'stretch'}
        >
          <Col offset={3} span={24}>
            <LogoView />
          </Col>
          <Col xs={20} md={18} lg={20} xl={16}>
            <Title translate={'no'} type={'light'} level={1}>
              {currentSlide.title}
            </Title>
          </Col>
          <Col offset={2} span={24}>
            <Title translate={'no'} type={'light'} level={5}>
              {currentSlide.subtitle}
            </Title>
          </Col>
        </Row>
      )}
    </Col>
  )
}

const PublicView: FC<PropsWithChildren> = ({ children = <Outlet /> }) => (
  <Row justify={'center'} align={'middle'} style={{ height: '100vh', width: '100%' }}>
    <RenderOn desktop>
      <SlidesCol xs={0} sm={0} md={12} lg={12} />
    </RenderOn>
    <Col xs={24} sm={24} md={12} lg={12}>
      <Row justify={'center'} align={'middle'} gutter={[0, 80]}>
        <Col span={24}>{children}</Col>
      </Row>
    </Col>
  </Row>
)

export default PublicView
