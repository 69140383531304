import { Entity } from '@data-client/rest'

import { createApiResource } from './api/endpoint'
import ApiEntity from './api/entity'

class BaseAddress extends Entity implements Data.Address {
  address: string = ''
  address2?: string = ''
  address3?: string = ''
  city: string = ''
  state: string = ''
  zipCode: string = ''
  country: string = ''

  get cityStateZip(): string {
    return [[this.city, this.state].filter(Boolean).join(' '), this.zipCode].filter(Boolean).join(', ')
  }

  get street(): string {
    return [this.address, this.address2, this.address3].filter(Boolean).join('| ')
  }

  get fullAddress() {
    return [this.street, this.cityStateZip].filter(Boolean).join(', ')
  }
  set fullAddress(value: string) {}
  pk(parent?: any, key?: string, args?: readonly any[]): string | undefined {
    return [this.country, this.zipCode].join(':')
  }
}

class AccountAddress extends ApiEntity implements Data.Address {
  firstName: string = ''
  lastName: string = ''
  companyName: string = ''
  notes: string = ''
  phoneNumber: string = ''
  verified: boolean = false
  default: boolean = false
  type: API.AddressType = 'shipping'
  address: string = ''
  address2?: string = ''
  address3?: string = ''
  city: string = ''
  state: string = ''
  zipCode: string = ''
  country: string = ''

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}

class BillingAddress extends BaseAddress {
  name = ''
  type: API.AddressType = 'billing'
}

const AccountAddressResource = createApiResource({
  path: '/api/account/addresses/:id',
  schema: AccountAddress,
})

export { AccountAddress, BaseAddress, BillingAddress, AccountAddressResource }
