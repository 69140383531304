import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'

import { Badge, Layout as BaseLayout, Col, LayoutProps, Row, Space } from 'antd'
import { SpaceProps as Props } from 'antd/es/space'

import { withPrefix } from '../../providers/ConfigProvider'
import Text from '..//text/Text'
import { useColor } from '../app'
import { useResponsive } from '../screen'
import { Title } from '../text/Title'

type SectionProps = {
  title?: ReactNode
  subTitle?: ReactNode
  titleSize?: 1 | 2 | 3 | 5 | 4
  titleType?: SDK.Components.ColorVariant
  size?: number
  className?: string
  style?: CSSProperties
  loading?: boolean
  extra?: ReactNode
  badgeCount?: number
  onHandleTextClick?: () => void
} & PropsWithChildren

type SpaceProps = Props & {
  bordered?: boolean
  justify?: '' | 'left' | 'center' | 'right' | 'space-between'
}

const VerticalSpace: FC<SpaceProps> = ({ bordered = false, justify = '', style, children, ...baseProps }) => {
  const { token } = useColor()
  return (
    <Space
      direction={'vertical'}
      style={{
        borderRadius: token.borderRadius,
        border: bordered ? `1px solid ${token.colorBorder}` : 'none',
        width: '100%',
        justifyContent: justify,
        ...style,
      }}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

const HorizontalSpace: FC<SpaceProps> = ({ justify = '', style, children, ...baseProps }) => {
  return (
    <Space
      direction={'horizontal'}
      wrap={false}
      style={{ width: '100%', justifyContent: justify, ...style }}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

const Split: FC<Props> = ({ style, children, direction = 'horizontal', ...baseProps }) => (
  <Space
    direction={direction}
    wrap={false}
    style={{ width: '100%', justifyContent: 'space-between', ...style }}
    {...baseProps}
  >
    {children}
  </Space>
)

const Section: FC<SectionProps> = ({
  title,
  subTitle,
  size = 32,
  titleSize = 3,
  loading = false,
  style = {},
  badgeCount,
  extra,
  children,
}) => (
  <Row
    gutter={[0, size]}
    style={style}
    className={withPrefix('section-header')}
    justify={'space-between'}
    align={'middle'}
  >
    <Col span={24}>
      <Row>
        <Col span={24}>
          <Row wrap={false} align={'middle'} justify={'space-between'}>
            <Col>
              {typeof title === 'string' ? (
                <Title translate={'yes'} loading={loading} level={titleSize}>
                  {title}
                </Title>
              ) : (
                title
              )}
              {badgeCount && <Badge color={'var(--primary-color)'} count={badgeCount} overflowCount={999} />}
            </Col>
            {extra && <Col>{extra}</Col>}
          </Row>
        </Col>
        {subTitle && (
          <Col span={24}>
            <Text translate={'no'} type={'secondary'}>
              {subTitle}
            </Text>
          </Col>
        )}
      </Row>
    </Col>
    <Col span={24}>{children}</Col>
  </Row>
)

const Layout: FC<PropsWithChildren<LayoutProps>> = ({ className: defaultClassName = '', ...props }) => {
  const { components } = useColor()
  const { isMobile, isTablet } = useResponsive()
  const siderCurrentWidth = props.hasSider
    ? isMobile
      ? 0
      : isTablet
        ? components['Layout.Sider'].collapsedWidth
        : components['Layout.Sider'].width
    : 0

  return (
    <BaseLayout
      style={{
        height: '100%',
        minHeight: '100vh',
        marginLeft: siderCurrentWidth,
      }}
      {...props}
    />
  )
}

export { Section, HorizontalSpace, VerticalSpace, Split, Layout, type SectionProps }
export { Header } from './Header'
export { CenteredContent } from './CenteredContent'
