import { useDispatch } from 'react-redux'

import { combineReducers, configureStore } from '@reduxjs/toolkit'

import app from './appSlice'
import chat from './chatSlice'
import checkout from './checkoutSlice'
import { controller } from './dataSlice'
import drawer from './drawerSlice'

const useAppDispatch = () => useDispatch()

const rootReducer = combineReducers({ app, checkout, drawer, chat })

const store = configureStore({
  reducer: rootReducer,
})

const appSelector = (state: SDK.State.RootState) => state.app
export { appSelector, store, controller, useAppDispatch }
export default store
