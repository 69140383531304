import { DateTz } from '../../../api'
import { dayjs } from '../../../services/dayjs'
import { ApiEntity } from '../../api/entity'
import { BusinessLocation } from '../../business'
import { CompanyConfig } from '../../company'
import { TimeSlot } from '../../scheduler'
import { SurveyEntity } from '../../survey'
import { CustomerEntity } from '../account'

class AccountBooking extends ApiEntity implements Data.TemporalLocal {
  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    startDateLocal: DateTz,
    endDateLocal: DateTz,
  }
  readonly blockType: string = ''
  readonly slot: TimeSlot = TimeSlot.fromJS()
  readonly status: API.AccountBookingStatus = 'booked'
  readonly partySize: number = 0
  readonly canCancel: boolean = false
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly location?: BusinessLocation
  readonly timezoneId: string = ''
  readonly hasSurvey: boolean = false
  readonly surveyId: number = 0
  readonly survey?: SurveyEntity
  readonly type: API.ScheduleItemType = 'appointment'
  readonly company: CompanyConfig = CompanyConfig.fromJS({})
  readonly guest?: CustomerEntity
  readonly owner?: CustomerEntity

  static get key() {
    return `AccountBooking`
  }
}

export { AccountBooking }
