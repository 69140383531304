import { FC, PropsWithChildren } from 'react'

import { Flex } from 'antd'

export type CenteredContentProps = {
  width?: string | number
  verticalAlign?: boolean
} & PropsWithChildren

export const CenteredContent: FC<CenteredContentProps> = ({ width = 930, verticalAlign = false, children }) => {
  return (
    <Flex align={verticalAlign ? 'center' : 'start'} justify={'center'} style={{ width: '100%' }}>
      <div style={{ width: '100%', maxWidth: width }}>{children}</div>
    </Flex>
  )
}
