import { createSlice } from '@reduxjs/toolkit'

const checkoutSlice = createSlice({
  name: 'app',
  initialState: {
    menuDrawerVisible: false,
  },
  reducers: {
    setMenuDrawerVisible(state, action: { payload: boolean }) {
      state.menuDrawerVisible = action.payload
    },
  },
})

const { setMenuDrawerVisible } = checkoutSlice.actions

export { setMenuDrawerVisible }

export default checkoutSlice.reducer
