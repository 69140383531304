import { ApiEntity } from './api/entity'
import { createApiResource } from './api/endpoint'
import { Category } from './category'
import { ImageEntity } from './media'

class ValueEntity {
  readonly selected: boolean = true
  readonly value: string = ''
}

class FieldEntity {
  readonly editable: boolean = true
  readonly placeholder: string = ''
  readonly type: string = ''
  readonly value: string = ''
  readonly values: ValueEntity[] = []
}

class AttributeEntity {
  readonly id: number = 0
  readonly name: string = ''
  readonly field: FieldEntity = new FieldEntity()
}

type ProductFilters = {
  businessId?: Data.ID
  orderBy?: 'id' | 'name' | 'price' | 'newest'
  categoryId?: string[]
  group?: string
  notGroup?: string
  type?: API.ProductType
  flag?: 'featured' | 'frontpage' | 'discontinued' | 'special order'
}

class Product extends ApiEntity implements Data.Categorized<Category>, Data.Grouped<API.ProductGroup>, Data.Imaginated {
  static readonly urlRoot = `/api/products`

  protected static readonly group: string = ''
  readonly id: Data.ID = ''
  readonly name: string = ''
  readonly description: string = ''
  readonly hasInventory: boolean = false
  readonly allowBackorders: boolean = false
  readonly categories: Category[] = []
  readonly price: number = 0.0
  readonly photos?: ImageEntity[] = []
  readonly attributes: AttributeEntity[] = []
  readonly group: API.ProductGroup = 'product'
  readonly type: API.ProductType = 'physical'

  get image() {
    return this.photos && this.photos.length > 0 ? this.photos[0].url : ''
  }
  get images(): string[] {
    return this.photos && this.photos?.length > 0 ? this.photos.map((value) => value.url) : []
  }
}

const ProductResourceBase = createApiResource({
  path: '/api/products/:id',
  schema: Product,
})

const ProductResource = {
  ...ProductResourceBase,
  getList: ProductResourceBase.getList.extend({ searchParams: {} as ProductFilters }),
}

export { Product, ProductResource }
