import { lazy } from 'react'

import Result from '@peoplevine/sdk/components/result'
import routes from '@peoplevine/sdk/routes'

type RoutePath = keyof typeof routes

const guestRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  verifyEmail: { Component: lazy(() => import('src/components/user/verifyEmail/VerifyEmail')) },
  calendarRedirect: { Component: lazy(() => import('src/components/redirect/CalendarRedirect')) },
  faq: { Component: lazy(() => import('src/components/faq/Faq')) },
  forgotPassword: { Component: lazy(() => import('src/components/user/password/reset/ForgotPassword')) },
  guestPassShare: { Component: lazy(() => import('src/components/public/guest-pass/GuestPass')) },
  guestTicket: { Component: lazy(() => import('src/components/public/guest-ticket/GuestTicket')) },
  passbookDownload: { Component: lazy(() => import('src/components/passbook/PassDownload')) },
  register: { Component: lazy(() => import('src/components/user/CreateAccount')) },
  static: { Component: lazy(() => import('src/components/index/Static')) },
  unsubscribeEmail: { Component: lazy(() => import('src/components/actions/EmailUnsubscribe')) },
  userLogin: { Component: lazy(() => import('src/components/user/Login')) },
  userPasswordReset: { Component: lazy(() => import('src/components/user/password/reset/PasswordReset')) },
}

const drawerRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  accountDue: {
    Component: lazy(() => import('src/components/account/tabs/statement/membership/MembershipFeesStatement')),
  },
  accountMembershipNomination: {
    Component: lazy(() => import('src/components/account/tabs/membership/form/MemberNominationDrawer')),
  },
  accountMembershipSubmember: {
    Component: lazy(() => import('src/components/account/tabs/membership/form/AddSubMember')),
  },
  accountOrder: {
    Component: lazy(() => import('src/components/account/tabs/order/Order')),
  },
  accountStatement: {
    Component: lazy(() => import('src/components/account/tabs/statement/Statement')),
  },
  accountTransaction: {
    Component: lazy(() => import('src/components/transactions/TransactionDetails')),
  },
  accountWalletBank: {
    Component: lazy(() => import('src/components/account/tabs/wallet/bank-account/BankAccountView')),
  },
  accountWalletCreate: {
    Component: lazy(() => import('src/components/account/tabs/wallet/payment-methods/PaymentMethodAdd')),
  },
  accountWalletCreditCard: {
    Component: lazy(() => import('src/components/account/tabs/wallet/credit-card/CreditCardEdit')),
  },
  accountWalletTicket: {
    Component: lazy(() => import('src/components/my-schedule/bookings/EventDetails')),
  },
  myScheduleEvent: {
    Component: lazy(() => import('src/components/my-schedule/bookings/EventDetails')),
  },
  blogId: {
    Component: lazy(() => import('src/components/blog/PostDetails')),
    aliases: [`/blog/:id`],
  },
  clubBenefits: {
    Component: lazy(() => import('src/components/perks/Details')),
  },
  myScheduleAppointments: {
    Component: lazy(() => import('src/components/my-schedule/bookings/AppointmentDetails')),
  },
  myScheduleReservations: {
    Component: lazy(() => import('src/components/my-schedule/bookings/ReservationDetails')),
  },
  survey: {
    Component: lazy(() => import('src/components/surveys/SurveyView')),
    aliases: ['/surveys/:id', '/form/:id'],
  },
  voucher: {
    Component: lazy(() => import('src/components/perks/VoucherDetails')),
  },
}

const memberRoutes: Partial<{ [key in keyof typeof routes]: SDK.Components.Route }> = {
  account: {
    Component: lazy(() => import('src/components/account/Account')),
    membersOnly: true,
    path: `${routes.account}/:root/:tab?`,
  },
  accountTab: {
    Component: lazy(() => import('src/components/account/Account')),
    membersOnly: true,
  },
  accountEvent: {
    Component: lazy(() => import('src/components/account/Event')),
  },
  activity: {
    Component: lazy(() => import('src/components/activities/Activities')),
    membersOnly: false,
  },
  appointment: {
    Component: lazy(() => import('src/components/appointment/Appointment')),
    membersOnly: false,
  },
  appointments: {
    Component: lazy(() => import('src/components/appointment/Appointments')),
    membersOnly: false,
  },
  checkout: {
    Component: lazy(() => import('src/components/checkout/Checkout')),
    membersOnly: false,
  },
  clubBenefitsTab: {
    Component: lazy(() => import('src/components/perks/Perks')),
    membersOnly: false,
  },
  dining: {
    Component: lazy(() => import('src/components/dining/restaurants/Restaurant')),
    membersOnly: false,
  },
  dinings: {
    Component: lazy(() => import('src/components/dining/restaurants/Restaurants')),
    membersOnly: false,
  },
  directory: {
    Component: lazy(() => import('src/components/directory/Directories')),
    membersOnly: true,
  },
  directoryTab: {
    Component: lazy(() => import('src/components/directory/Directories')),
    membersOnly: true,
  },
  directoryMember: {
    Component: lazy(() => import('src/components/directory/member/Member')),
    membersOnly: true,
  },
  directoryCompany: {
    Component: lazy(() => import('src/components/directory/Business')),
  },
  event: {
    Component: lazy(() => import('src/components/event/Event')),
    membersOnly: false,
    aliases: ['/event/:id'],
  },
  events: {
    Component: lazy(() => import('src/components/event/Events')),
    membersOnly: false,
  },
  guestList: {
    Component: lazy(() => import('src/components/guest-list/GuestList')),
    membersOnly: false,
  },
  index: {
    Component: lazy(() => import('src/components/index/MyClub')),
  },
  message: {
    Component: lazy(() => import('src/components/messaging/Messaging')),
    membersOnly: false,
  },
  myClub: {
    Component: lazy(() => import('src/components/index/MyClub')),
  },
  mySchedule: {
    Component: lazy(() => import('src/components/my-schedule/MySchedule')),
  },
  notifications: {
    Component: lazy(() => import('src/components/notifications/Notifications')),
  },
  pageId: {
    Component: lazy(() => import('src/components/pages/Page')),
    membersOnly: false,
  },
  room: {
    Component: lazy(() => import('src/components/rooms/Room')),
    membersOnly: false,
  },
  rooms: {
    Component: lazy(() => import('src/components/rooms/Rooms')),
    membersOnly: false,
  },
  spa: {
    Component: lazy(() => import('src/components/spa/Spa')),
    membersOnly: true,
  },
  spas: {
    Component: lazy(() => import('src/components/spa/Spas')),
    membersOnly: true,
  },
  userLogout: {
    Component: lazy(() => import('src/components/user/Logout')),
    membersOnly: false,
  },
  _wildcard: {
    Component: Result.NotFound,
  },
}

function hasRoute(path: keyof typeof routes) {
  return memberRoutes[path] !== undefined
}

export { hasRoute, type RoutePath, memberRoutes, guestRoutes, drawerRoutes }
