import { FC, PropsWithChildren } from 'react'

import { ConfigProvider, Typography } from 'antd'

import { useColor } from '../app'
import { Skeleton } from '../skeleton/Skeleton'
import { withTranslation } from './Text'

type TitleProps = SDK.Components.TextProps & {
  level?: 1 | 2 | 3 | 4 | 5
  fontSize?: string | number
  weight?: number
  fontFamily?: 'primary' | 'secondary'
}

const Title: FC<PropsWithChildren<TitleProps>> = withTranslation(
  ({
    loading = false,
    uppercased = false,
    type = 'default',
    level = 5,
    size,
    weight: defaultWeight,
    fontSize: defaultSize,
    translate = 'yes',
    font = {
      family: 'primary',
      weight: defaultWeight,
      size: defaultSize,
    },
    children,
    ...props
  }) => {
    const { textByVariant } = useColor()
    const { token } = useColor()
    const fontFamily = font?.family === 'secondary' || level === 1 ? token?.fontFamilySecondary : token?.fontFamily
    if (loading) return <Skeleton height={token[`fontSizeHeading${level}`]} width={200} />
    return (
      <ConfigProvider
        componentSize={size}
        theme={{
          components: {
            Typography: {
              fontFamily,
              colorText: type ? textByVariant(type) : props.color,
              algorithm: true,
            },
          },
        }}
        typography={{
          style: {
            textOverflow: 'ellipsis',
            margin: 0,
            marginBlockEnd: 0,
            lineHeight: token[`lineHeightHeading${level}`],
            fontWeight: font.weight ? font.weight : level === 1 || font.family === 'secondary' ? 800 : 900,
            textTransform: level === 1 || uppercased ? 'uppercase' : 'inherit',
            color: props.color,
          },
        }}
      >
        <Typography.Title data-cy={'Title'} data-title={children} level={level} {...props}>
          {children}
        </Typography.Title>
      </ConfigProvider>
    )
  },
)

export { Title }
