import { ReportCallback } from 'web-vitals'

function report(onPerfEntry?: ReportCallback): void {
  if (onPerfEntry) {
    void import('web-vitals').then(({ onCLS, onFID, onFCP, onLCP, onTTFB, onINP }) => {
      onCLS(onPerfEntry)
      onFID(onPerfEntry)
      onFCP(onPerfEntry)
      onLCP(onPerfEntry)
      onTTFB(onPerfEntry)
      onINP(onPerfEntry)
    })
  }
}

export { report }
