import React, {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { useConfig, useMenuConfig } from '../../providers'

import PageHeaderProps = SDK.Components.PageHeaderProps

const Context = createContext<
  SDK.Components.PageHeaderProps & {
    setPageTitle: Dispatch<SetStateAction<ReactNode>>
    addBreadcrumbs: Dispatch<SetStateAction<{}>>
  }
>({
  title: '',
  breadcrumbs: {},
  extra: null,
  setPageTitle: () => {},
  addBreadcrumbs: () => {},
})

const Provider: FC<PropsWithChildren<Partial<SDK.Components.PageHeaderProps>>> = ({
  title = '',
  breadcrumbs: defaultBreadcrumbs = {},
  extra,
  children,
}) => {
  const { pathname } = useLocation()
  const { pageByUri } = useConfig().portal
  const { pageByUri: menuPageByUri } = useMenuConfig()

  const pageTitle = title ? title : pageByUri(pathname)?.name ?? menuPageByUri(pathname)?.name

  const breadcrumbs = useMemo(() => {
    const breadcrumbItems = Object.entries(defaultBreadcrumbs ?? {})
      .filter(([breadcrumbName]) => breadcrumbName !== '')
      .map(([breadcrumbName, path]) => ({
        title: breadcrumbName,
        path: path,
      }))

    if (breadcrumbItems.length === 0 || (breadcrumbItems[0].path !== '/' && '/' !== pathname))
      breadcrumbItems.unshift({
        title: 'Home',
        path: '/',
      })

    if (breadcrumbItems[breadcrumbItems.length - 1]?.path !== pathname && pageTitle && typeof pageTitle === 'string')
      breadcrumbItems.push({
        title: pageTitle,
        path: pathname,
      })

    return breadcrumbItems.reduce((acc, { path, title }) => ({ ...acc, [title]: path }), {})
  }, [defaultBreadcrumbs, pathname, pageTitle])

  const [state, setState] = useState<PageHeaderProps>({ title: pageTitle, breadcrumbs, extra })

  const setPageTitle = useCallback((title: ReactNode) => {
    title && setState((prevState) => ({ ...prevState, title }))
  }, [])
  const addBreadcrumbs = useCallback((breadcrumbs: { [key: string]: string }) => {
    breadcrumbs &&
      setState((prevState) => ({ ...prevState, breadcrumbs: { ...prevState.breadcrumbs, ...breadcrumbs } }))
  }, [])

  return <Context.Provider value={{ ...state, setPageTitle, addBreadcrumbs }}>{children}</Context.Provider>
}

const usePageHeader = () => useContext(Context)

export { Context, Provider, usePageHeader }
