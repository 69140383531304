import { Entity } from '@data-client/rest'

export class ScanData extends Entity implements Data.ScanData {
  readonly value: string
  readonly url: string = ''
  readonly format: Data.BarcodeType = 'PKBarcodeFormatQR'
  readonly type: Data.ScanDataType

  pk() {
    return this.url
  }
}
