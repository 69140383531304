import { Controller } from '@data-client/core'
import {
  DevToolsManager,
  NetworkManager,
  PollingSubscription,
  PromiseifyMiddleware,
  SubscriptionManager,
  applyManager,
  createReducer as createDataReducer,
  initialState,
} from '@data-client/redux'

import { createReducer, createSlice } from '@reduxjs/toolkit'

const controller = new Controller()
const managers = [new NetworkManager(), new DevToolsManager({}), new SubscriptionManager(PollingSubscription)]
const middleware = applyManager(managers, controller).concat(PromiseifyMiddleware)

const reducer = createDataReducer(controller)
export { controller, middleware, reducer }
const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    data: createReducer(initialState, (builder) => builder.addDefaultCase(createDataReducer(controller))),
  },
})
export default slice.reducer
