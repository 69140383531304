import { FC } from 'react'

import { Avatar as BaseAvatar } from 'antd'

import { useColor } from '../app'
import IvyIcon from '../icon'
import { Skeleton } from '../skeleton/Skeleton'
import { Image } from './Image'

const assets: Record<`./assets/placeholders/${Design.PlaceholderIcon}.svg`, string> = import.meta.glob(
  './assets/placeholders/*.svg',
  {
    import: 'default',
    eager: true,
  },
)
const Avatar: FC<SDK.Components.AvatarProps> = ({
  size = 160,
  loading = false,
  text: children,
  background,
  className = '',
  icon,
  shape: defaultShape = 'circle',
  src,
  placeholder,
  ...props
}) => {
  const { token } = useColor()
  const backgroundColor = '#f8f8f8'
  const shape = !!defaultShape ? defaultShape : icon ? 'square' : 'circle'
  return (
    <BaseAvatar
      shape={shape}
      size={size}
      style={{
        objectFit: 'cover',
        objectPosition: 'center',
        margin: '0 auto',
        backgroundColor,
        backgroundImage: placeholder && `url("${assets[`./assets/placeholders/${placeholder}.svg`]}")`,
        backgroundSize: size / 2,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        border: shape === 'circle' ? `4px solid ${token.colorBorder}` : undefined,
        ...props.style,
      }}
      src={
        typeof src === 'string' && src ? (
          src
        ) : typeof icon === 'string' ? (
          <IvyIcon color={'primary'} type={icon as Design.IvyIcon} height={size / 2} />
        ) : (
          icon
        )
      }
      {...props}
    >
      {loading ? (
        <Skeleton enableAnimation height={'100%'} width={'100%'} />
      ) : typeof src === 'string' ? (
        <Image placeholder={placeholder} src={src} />
      ) : (
        children
      )}
    </BaseAvatar>
  )
}
export { Avatar }
