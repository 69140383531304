import type { NetworkError } from '@data-client/react'
import { Entity, type ResourceGenerics, RestEndpoint, RestGenerics, createResource } from '@data-client/rest'

import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import { ApiError } from '../../api'
import { ApiCollection } from './entity'
import { PaginatedHeader, PaginatedResponse } from './pagination'

class ApiEndpoint<R extends RestGenerics = any> extends RestEndpoint<R> {
  static sanitizeParams<
    T extends {
      [key: string]: string | Array<string | number> | number | boolean | undefined | null
    },
  >(args: T): API.SearchParams {
    return Object.entries(args)
      .filter(([, v]) => typeof v !== 'undefined' && v !== undefined && typeof v !== null)
      .reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: Array.isArray(v) ? v.map(String).join(',') : v && v.toString(),
        }),
        {},
      )
  }

  url<T extends API.Params>(...args: T[]): string {
    const sanitized = args.map((it) => ApiEndpoint.sanitizeParams(it))
    return super.url(...(snakecaseKeys(sanitized, { deep: true }) as never))
  }

  getHeaders(headers: HeadersInit): HeadersInit {
    const language = `${JSON.parse(localStorage.getItem('locale') ?? JSON.stringify('en-US'))}`.split('-')[0]
    return {
      ...headers,
      ['Content-Type']: 'application/json',
      ['Accept']: 'application/json',
      ['Accept-Language']: language,
    }
  }

  async getRequestInit(body: any) {
    if (body) {
      if (body instanceof File) {
        const formData = new FormData()
        formData.append('file', body)
        body = formData
      } else {
        return super.getRequestInit(typeof body !== 'string' ? snakecaseKeys(body) : body)
      }
    }

    const requestInit: RequestInit = await super.getRequestInit(body)
    requestInit.credentials = 'include'
    return requestInit
  }

  async fetchResponse(input: RequestInfo, init: RequestInit): Promise<Response> {
    const response = await super.fetchResponse(input, init).catch(async (error: NetworkError) => {
      if (error.status >= 400) {
        const data = await error.response?.json().catch(() => ({}))
        throw new ApiError({ data, ...error.response }, error)
      } else {
        return error.response
      }
    })

    return response ?? Response.error()
  }

  async parseResponse(response: Response): Promise<any> {
    const data = await response.json().catch((error) => {
      console.log(error.response)
      return error
    })
    if (response.status === 204) {
      return []
    } else {
      return data
    }
  }

  process<T>(value: any, ...args: any): T {
    return camelcaseKeys(value, { deep: true, exclude: ['messages'] })
  }
}

function paginationExtension<S extends new () => Entity, P extends API.PaginationParams & API.SortingParams>({
  schema,
  searchParams,
}: {
  schema: S
  searchParams?: P
}) {
  return {
    schema: { results: new ApiCollection<S[]>([schema]), pagination: new PaginatedHeader() },
    searchParams: searchParams ?? ({} as P & API.PaginationParams & API.SortingParams),
    async parseResponse(response: Response | undefined): Promise<PaginatedResponse<S>> {
      const results: S[] = await response
        ?.json()
        .then((value) => (value ? value : []))
        .catch(() => [])
      const pagination = response?.headers?.get('pagination') ?? response?.headers?.get('Pagination')
      return PaginatedResponse.fromJS<typeof PaginatedResponse<S>>({
        results,
        pagination: PaginatedHeader.fromJS(pagination ? JSON.parse(pagination) : {}),
      })
    },
  }
}

const createApiResource = <S extends new () => Entity, P extends { [key: string]: string | number } = API.Params>({
  singleton = false,
  path = '',
  searchParams,
  schema,
  ...params
}: ResourceGenerics & {
  singleton?: boolean
  schema: S
}) => {
  const base = createResource({
    path: singleton ? path.replace('/:id', '/:id?') : path,
    Endpoint: ApiEndpoint,
    searchParams,
    Collection: ApiCollection,
    schema,
    ...params,
  })

  const paginatedList = base.getList.extend({
    ...paginationExtension({
      schema,
      searchParams: base.getList.searchParams,
    }),
    process(value: any, ...args: any) {
      return camelcaseKeys(value, { deep: true })
    },
    sideEffect: undefined,
  })

  const infinitePagination = base.getList.extend({
    paginationField: 'pageNumber',
    ...paginationExtension({
      schema,
    }),
  })

  return {
    ...base,
    create: base.getList.push,
    infinitePagination,
    paginatedList,
  }
}

export { ApiEndpoint, createApiResource, paginationExtension }
