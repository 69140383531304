import { useCallback } from 'react'

import { App, Flex, NotificationArgsProps } from 'antd'

import { ApiError } from '../api'
import { useColor } from '../components/app'
import IvyIcon from '../components/icon'
import Text from '../components/text/Text'
import { Title } from '../components/text/Title'

type ContextProps = {
  notify: (props: NotificationArgsProps) => void
  notifyOnError: (error: Partial<Pick<Error, 'name' | 'message'>>) => void
}

const iconMap: { [key in NonNullable<NotificationArgsProps['type']>]: Design.SymbolIcon } = {
  success: 'check-circle',
  error: 'warning',
  warning: 'warning',
  info: 'info-circle',
}
const useNotification: () => ContextProps = () => {
  const { notification } = App.useApp()
  const { bgByVariant, token, textByBgVariant } = useColor()
  const notify = useCallback(
    ({ type = 'success', message, description }: NotificationArgsProps) => {
      notification.open({
        type,
        icon: <IvyIcon color={type} type={`symbol/${iconMap[type] || 'info-circle'}`} style={{display: 'flex', marginTop: 2,}} />,
        closeIcon: false,
        style: {
          backgroundColor: bgByVariant(type),
          borderRadius: token.borderRadius,
        },
        message: (
          <Title level={5} color={textByBgVariant(type)}>
            {message}
          </Title>
        ),
        description,
      })
    },
    [bgByVariant, notification, textByBgVariant, token.borderRadius],
  )

  const notifyOnError = useCallback(
    (error: Partial<ApiError>) => {
      console.log(error)
      notify({
        type: 'error',
        message: error?.name,
        description: error?.message ? (
          <Flex vertical gap={2}>
            <Text type={'error'}>{error.message}</Text>
            {error?.requestId && (
              <Text type={'secondary'} translate={'no'}>
                Request ID: <code>{error.requestId}</code>
              </Text>
            )}
          </Flex>
        ) : (
          error?.message
        ),
      })
    },
    [notify],
  )

  return { notify, notifyOnError }
}

export { useNotification }
