import { FC, PropsWithChildren, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Layout } from 'antd'

import { Spinner } from '@peoplevine/sdk/components/loader'

import PublicView from 'src/components/public'

const Unauthorized: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
  return (
    <Layout>
      <PublicView>
        <Suspense fallback={<Spinner />}>{children}</Suspense>
      </PublicView>
    </Layout>
  )
}
export default Unauthorized
