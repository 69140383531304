import type { PolymorphicInterface } from '@data-client/endpoint/lib/interface'
import { Entity, schema } from '@data-client/rest'

abstract class ApiEntity extends Entity implements Data.Identified<Data.ID> {
  id: Data.ID = ''

  pk(): string {
    return `${this.id}`
  }
}

class ApiObject extends Entity {
  pk(): undefined {
    return undefined
  }
}

class Singleton extends ApiEntity {
  pk(parent?: any, key?: string, args?: readonly any[]): string {
    return 'Singleton'
  }
}

class ApiCollection<
  S extends any[] | PolymorphicInterface = any,
  Parent extends any[] = [urlParams: Record<string, any>, body?: Record<string, any>],
> extends schema.Collection<S, Parent> {
  static nonFilterArgumentKeys(key: keyof API.Pagination) {
    return key === 'orderBy' || key === 'pageNumber' || key === 'pageSize'
  }
}

export default ApiEntity

export { ApiEntity, Singleton, ApiObject, ApiCollection }
